import React from "react";
import "../Styles/Footer.css";
import SubscribeNewsletter from "./SubscribeNewsletter";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footer-section">
      <div className="footer-container">
        <div className="ft-info">
          <div className="ft-info-p1">
            <p className="ft-title">
              Windshield Guru
            </p>
            <p className="ft-description">
            With Windshield Guru, expert glass replacement services are just a click away. 
            Schedule your appointment or get an instant quote for your glass needs now. 
            On-demand car glass solutions are at your fingertips—convenience, quality, and peace of mind with every service.
            </p>
          </div>

          <SubscribeNewsletter />
        </div>

        <div className="ft-list">
          <p className="ft-list-title">Services</p>
          <ul className="ft-list-items">
            <li>
              <a href="#car-details-container">Check Price</a>
            </li>
            <li>
              <a href="#heroSection">Glass Replacement</a>
            </li>
            <li>
              <a href="#services">Doorstep Service</a>
            </li>
            <li>
              <a href="#bookAppointments">Book Appointment</a>
            </li>
            <li>
            <a href="https://wa.me/9310352465">Renew Your Insurance Policy</a>
            </li>
          </ul>
        </div>

        <div className="ft-list">
          <p className="ft-list-title">Legal</p>
          <ul className="ft-list-items">
            <li>
              <Link to={"/general"}>Terms of Service</Link>
            </li>
            <li>
              <Link to={"/privacy"}>Privacy Policy</Link>
            </li>
            
            <li>
              <Link to={"/blog"}>Blog</Link>
            </li>
            <li>
            <a href="#services">How it Works</a>
            </li>
          </ul>
        </div>

        <div className="ft-list" id="contact">
          <p className="ft-list-title">Talk To Us</p>
          <ul className="ft-list-items">
          
            <li>
              <a href="mailto:windshieldguruinfo@gmail.com">
                windshieldguruinfo@gmail.com
              </a>
            </li>
            <li>
              <a href="tel:+91 9671032624">+91 96710 32624</a>
            </li>
            <li>
              <a href="tel:+91 9310352465">+91 93103 52465</a>
            </li>
          </ul>
        </div>
      </div>

      <div className="ft-copyright">
        <p>© 2024 Windshield Guru. All rights reserved.</p>

        <ul className="ft-social-links">
          <li>
            <a
              href="https://www.linkedin.com/company/windshield-gurus/"
              title="LinkedIn"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
              </svg>
            </a>
          </li>

          <li>
            <a
              href="https://www.facebook.com/profile.php?id=100091143909888"
              title="FaceBook"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 320 512"
              >
                <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
              </svg>
            </a>
          </li>



          {/* Updated instagram icon from twitter and used chat gpt to create the d path for instagram logo svg*/ }
          <li>
            <a
              href="https://www.instagram.com/windshieldguru/"
              title="Instagram"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                aria-label="Instagram"
                role="img"
                viewBox="0 0 512 512"
                style={{ width: '24px', height: '24px' }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  
                  d="M349.33,0H162.67C72.9,0,0,72.9,0,162.67V349.33C0,439.1,72.9,512,162.67,512H349.33C439.1,512,512,439.1,512,349.33V162.67C512,72.9,439.1,0,349.33,0ZM464,349.33C464,422.63,422.63,464,349.33,464H162.67C89.37,464,48,422.63,48,349.33V162.67C48,89.37,89.37,48,162.67,48H349.33C422.63,48,464,89.37,464,162.67Z"
                />
                <path
                  
                  d="M377.33,161.33A48,48,0,1,1,329.33,113.33,48,48,0,0,1,377.33,161.33Z"
                />
                <path
                  
                  d="M256,144C194.64,144,144,194.64,144,256S194.64,368,256,368,368,317.36,368,256,317.36,144,256,144Zm0,192a80,80,0,1,1,80-80A80,80,0,0,1,256,336Z"
                />
            </svg>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Footer;
