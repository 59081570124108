import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function SubscribeNewsletter() {
  const [inputEmail, setInputEmail] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

  const handleEmailInput = (event) => {
    setInputEmail(event.target.value);
  };
  const handleSubmitToGoogleForm = async (email) => {
    const formUrl = "https://docs.google.com/forms/u/0/d/e/1FAIpQLSf-6FdZXp1lbBKszuD3OgbrmqepX2pPyOMJmp3wdJjmBoCjTg/formResponse";
    const emailFieldName = 'entry.885278905'; // Replace with your field name
    const formData = new FormData();

    formData.append(emailFieldName, email);

    try {
       await fetch(formUrl, {
        method: 'POST',
        body: formData,
        mode: 'no-cors', // Google Forms require no-cors
      });

      
        toast.success("Subscribed to Newsletter!");
        //position: toast.POSITION.TOP_CENTER,
        
      
    } catch (error) {
      console.error('Error submitting to Google Forms', error);
      toast.error("Failed to subscribe. Please try again later.", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  
  const handleBookAppointmentClick = () => {
    

    if (!isButtonDisabled && emailRegex.test(inputEmail)) {
      
        setIsButtonDisabled(true);
        handleSubmitToGoogleForm(inputEmail).finally(() => {
          setInputEmail("");
          setIsButtonDisabled(false);
        });
      }
       else {
        toast.error("Invalid Email Address !", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    
  };

  return (
    <div className="ft-info-p2">
      <p className="ft-input-title">Stay Update to our Newsletter</p>
      <input
        type="text"
        inputMode="email"
        className="ft-input"
        placeholder="Enter your email address"
        name="email"
        value={inputEmail}
        onChange={handleEmailInput}
        autoComplete="true"
      />
      <button
        className="text-appointment-btn"
        type="button"
        disabled={isButtonDisabled}
        onClick={handleBookAppointmentClick}
      >
        Subscribe
      </button>

      <ToastContainer autoClose={4000} limit={1} closeButton={false} />
    </div>
  );
}

export default SubscribeNewsletter;
