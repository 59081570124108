import React from "react";
import Windshield from "../Assets/a guy inspecting windshield in close up.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck, faPhone} from "@fortawesome/free-solid-svg-icons";
import { useNavigate  } from "react-router-dom";
import "../Styles/Hero.css";

function Hero() {
  const navigate = useNavigate();
  const phoneNumber = "9671032624";

  const handleBookAppointmentClick = () => {
    navigate("/appointment");
  };
  

  return (
    <div className="section-container" id="heroSection">
      <div className="hero-section">
        <div className="text-section">
          <p className="text-headline">Clear Vision Ahead</p>
          <h2 className="text-title">
            Find your vehicle glass and book a doorstep appointment
          </h2>
          <p className="text-descritpion">
          Don't let a damaged windshield cloud your journey. Our certified experts are 
          at your service, offering quick, efficient, and top-quality solutions to 
          restore your vehicle’s glass to perfection. Check your glass price now and book your 
          slot to secure your clear path forward with Windshield Guru now—where excellence meets the road.
          </p>
          <button
            className="text-appointment-btn"
            type="button"
            onClick={handleBookAppointmentClick}
          >
            <FontAwesomeIcon icon={faCalendarCheck} /> Book Slot
          </button>

          <button
            
            className="call-now-btn"
            type="button"
            onClick={() => window.location.href = `tel:${phoneNumber}`}
          >
            <FontAwesomeIcon icon={faPhone} /> Call Now
          </button>


          <div className="text-stats">
            <div className="text-stats-container">
              <p>21000+</p>
              <p>Windshields Replaced</p>
            </div>

            <div className="text-stats-container">
              <p>20+</p>
              <p>Expert technicians</p>
            </div>

            <div className="text-stats-container">
              <p>10+</p>
              <p>Years of Experience</p>
            </div>
          </div>
        </div>

        <div className="hero-image-section">
          <img className="hero-image1" src={Windshield} alt="a guy inspecting windshield in close up" />
        </div>
      </div>

    </div>  
  );
}

export default Hero;
