import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import blogsData from './blogsData'; // Assuming you have an array of blog data
import '../Styles/BlogPost.css';
import { Link } from 'react-router-dom';

const BlogPost = () => {
  const { slug } = useParams();
  const [blogPost, setBlogPost] = useState(null);

  useEffect(() => {
    // Find the blog post by slug
    const post = blogsData.find(blog => blog.slug === slug);
    setBlogPost(post);
  }, [slug]);

  if (!blogPost) {
    return <div>Loading...</div>;
  }

  return (
    <div className="blog-section-title">
            <h1 className="blog-siteTitle">
                <Link to="/">
                Windshield Guru 
                </Link>
            </h1>

            <div className="blog-text-content">
                
                
                
            <section>

            <div className = "blog-container" id = "blog-container">
                <h1 className = "blog-page-title" id = "blog-title">{blogPost.title}</h1>
                <p className = "date" id = "date">{blogPost.date}</p>
                <div dangerouslySetInnerHTML={{ __html: blogPost.content }} />
            </div>
            </section>

            <div className="blog-footer">
                        <p>© 2024 Windshield Guru. All rights reserved.</p>
            </div>
    </div>
    </div>
  );
};

export default BlogPost;
