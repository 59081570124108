import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../Styles/GeneralPolicy.css";

function GeneralInfo() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });

const structuredData = {
  "@context": "https://schema.org",
  "@type": "ProfessionalService",
  "name": "Windshield Guru",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "31A, Sector 33, Near Hero Honda Chowk",
    "addressLocality": "Gurugram",
    "addressRegion": "HR",
    "postalCode": "122004",
    "addressCountry": "IN"
  },
  "email": "windshieldguruinfo@gmail.com",
  "url": "http://www.windshieldgurus.com"
};

return (
  <div className="general-section-title">
          <h1 className="general-siteTitle">
              <Link to="/">
              Windshield Guru 
              </Link>
          </h1>

          <div className="general-text-content">
  
            <p className="general-page-title">Terms of Service</p>
              <p className="general-description">Last Updated: March, 2024</p>

              <section>
                <p className="general-title">Introduction</p>
                <p className="general-description">Welcome to Windshield Guru your expert in automotive glass care. By accessing our website or using our services, 
                you agree to be bound by these Terms of Service. These terms apply to all visitors, users, and others who wish 
                to access or use the Service. Our Terms of Service govern your use of our website and services, 
                ensuring a transparent and trustworthy experience. These terms outline our mutual rights and obligations, 
                and by accessing our services, you accept these provisions in full. For a safe and clear journey, 
                trust in Windshield Guru's commitment to excellence.</p>
              </section>

              <section>
                <p className="general-title">Use of Our Service</p>
                <p className="general-description">At Windshield Guru, we specialize in providing top-tier automotive glass repair and replacement services. 
                Our offerings are designed to meet a wide range of needs, from windshield repairs to full glass replacements for cars, trucks, buses
                and other vehicles including all private and commercial vehicles. We emphasize the importance of using the highest quality materials and state-of-the-art techniques to ensure the 
                safety and satisfaction of our clients. By choosing our services, you're opting for a hassle-free experience, 
                backed by our commitment to excellence and customer care. Our team is here to assist you every step of the way, 
                from initial consultation to the final installation, making sure your vehicle's glass is in perfect condition. 
                The "Use of Our Services" section for the Windshield Guru Terms of Service outlines how customers can utilize the 
                services offered by Windshield Guru for automotive glass care. This includes the process for scheduling repair or replacement 
                appointments, the scope of services provided, payment terms, and any limitations or restrictions on service use. 
                It is important for users to familiarize themselves with these terms to ensure a smooth and satisfactory service experience.</p>
              </section>

              <section>
                <p className="general-title">User Responsibilities</p>
                <p className="general-description"> At Windshield Guru, we prioritize customer satisfaction and safety. As part of our commitment, 
                we emphasize the critical role of user responsibility. When utilizing our services, we expect customers to provide accurate and complete 
                information for service requests, ensuring efficient and high-quality service delivery. Timely payments for services rendered are crucial 
                for maintaining the trust and integrity of our operations. Additionally, we encourage users to adhere to safety instructions during 
                installations to protect both our technicians and customers. This collaborative approach ensures a seamless experience and upholds 
                our standard of excellence in automotive glass care.Misuse of the service may result in termination of your service.</p>
              </section>

              <section>
                <p className="general-title">Intellectual Property</p>
                <p className="general-description">At Windshield Guru, we recognize the value of innovation and creativity. Our services, 
                website content, and all related materials, including trademarks, logos, and software, are protected under intellectual property laws. 
                These assets are the exclusive property of Windshield Guru or its licensors. We encourage respect for these rights, 
                prohibiting the unauthorized use, reproduction, or distribution of our intellectual content. 
                This ensures that we can continue to offer high-quality services while fostering innovation within the automotive glass industry. 
                For any inquiries or permissions related to our intellectual property, please contact us directly. 
                The Service and its original content, features, and functionality are and will remain the exclusive property of 
                Windshield Guru and its licensors.</p>
              </section>

              <section>
                <p className="general-title">Links To Other Web Sites</p>
                <p className="general-description">Our Service may contain links to third-party web sites or services that are not owned or 
                controlled by Windshield Guru. We assume no responsibility for the content, privacy policies, or practices of any third-party web 
                sites or services. These links are provided for your convenience to access additional information. 
                We encourage you to read the terms and privacy policies of any third-party sites you visit. Your use of these external sites is at your own risk.</p>
              </section>

              <section>
                <p className="general-title">Amendments To Terms</p>
                <p className="general-description">We may amend Terms at any time by posting the amended terms on this site. It is your responsibility to review these Terms periodically.</p>
              </section>

              <section>
                <p className="general-title">Contact Us</p>
                <p className="general-description">
                For any questions, concerns, or feedback regarding our services or terms, please don't hesitate to reach out. 
                Contact us at windshieldguruinfo@gmail.com, or visit our website for more information. 
                Our dedicated team is committed to providing you with the support you need.</p>
              </section>

              <div className="general-footer">
                        <p>© 2024 Windshield Guru. All rights reserved.</p>
              </div>


              {/* Embedding JSON-LD structured data */}
              <script type="application/ld+json">
                {JSON.stringify(structuredData)}
              </script>
          </div>
  </div>
);
};

export default GeneralInfo;



