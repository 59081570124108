import React from 'react';
//import BlogPost from './BlogPost'; // Assume BlogPost is your blog post component
import '../Styles/Blog.css'; // Your CSS file path
import { Link } from 'react-router-dom';
import blogsData from '../Components/blogsData';


const Blog = () => {
  
  const structuredData = {
    
      "@context": "http://schema.org",
      "@type": "Blog",
      "name": "Windshield Guru Blog",
      "url": "http://www.windshieldgurus.com/blog",
      "description": "Explore the latest insights and tips on car glass care, windshield replacement, and automotive safety from the experts at Windshield Guru.",
      "publisher": {
        "@type": "Organization",
        "name": "Windshield Guru",
        "logo": {
          "@type": "ImageObject",
          "url": "http://www.windshieldgurus.com/logo.png"
        }
      },
      "blogPost": blogsData.map(blog => ({
        "@type": "BlogPosting",
        "headline": blog.title,
        "description": blog.summary,
        "datePublished": blog.date,
        "url": `http://www.windshieldgurus.com${blog.url}`
      }))
    
  };

  return (
    <div className="blog-list">
      <h1 className="blog-site-title">
        <Link to = '/blog'>
          Windshield Guru Blog
        </Link>
      </h1>
      {blogsData.map(blog => (
        <div className = 'blog-container' id = 'blog-container'>
        <div key={blog.id} className='blog-entry'>
          <h2 className='blog-title'>{blog.title}</h2>
          <p className='blog-date'>{blog.date}</p>
          <p className='blog-summary-text'>{blog.summary || blog.content.substring(0, 100) + '...'}</p> {/* Assuming you want to display a part of the content as a summary if `summary` is not provided */}
          <Link to={`/blog/${blog.slug}`} className='read-more-link'>Read More</Link> {/* Use `slug` for URL */}
        </div>
        </div>
      ))}
    
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
      />
    </div>
  );
};

export default Blog;
