import React from "react";
import DoctorCard from "./Card";
import profile1 from "../Assets/of a professional automotive technician replacing the windshield of a car..webp";
import profile2 from "../Assets/door glass replacement.webp";
import profile3 from "../Assets/sunroof replacement.webp";
import profile4 from "../Assets/setting within an auto insurance office.webp";
import "../Styles/Card.css";

function WorkWeDo() {
  return (
    <div className="doctor-section" id="doctors">
      <div className="dt-title-content">
        <h3 className="dt-title">
          <span>Our Servies</span>
        </h3>

        <p className="dt-description">
        Windshield Guru is your one-stop solution for auto glass excellence and insurance needs. 
        We offer comprehensive services including windshield, door glass, quarter panel, vent glass, sunroof, and backscreen replacement. 
        Beyond expert glass services, we specialize in handling and renewing automotive insurance policies, 
        ensuring seamless coverage and support for all your auto glass repairs and replacements. 
        Our skilled professionals are committed to delivering top-quality service, 
        ensuring your vehicle's safety and your satisfaction with every job.
        </p>
      </div>

      <div className="dt-cards-content">
        <DoctorCard
          img={profile1}
          name="Windshield Replacement"
          title="Deals in all kinds of windshields"
          
        />
        <DoctorCard
          img={profile2}
          name="Door Glass Replacement"
          title="Door Glass, Quarter Pannels, Vents, Side Window and every other glass need you have"
          
        />
        <DoctorCard
          img={profile3}
          name="Sunroof Replacement"
          title="Panaromic, Glass Fix and Comprehensive Sunroof services"
          
        />
        <DoctorCard
          img={profile4}
          name="Insurance Policies"
          title="specialize in handling and renewing automotive insurance policies"
    
        />
      </div>
    </div>
  );
}

export default WorkWeDo;
