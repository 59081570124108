import React from "react";
import InformationCard from "./InformationCard";
import {  faTruckMedical,  faIdCard,  faUserShield } from "@fortawesome/free-solid-svg-icons";
import "../Styles/Info.css";

function Info() {
  return (
    <div className="info-section" id="services">
      <div className="info-title-content">
        <h3 className="info-title">
          <span>What We Do</span>
        </h3>
        <p className="info-description">
        We deliver auto glass services right to your doorstep, providing a full spectrum 
        of on-demand car glass solutions designed around your needs. Our platform 
        connects you with skilled technicians who specialize in high-quality windshield 
        replacement and repair, ensuring your vehicle is always in top condition. 
        Whether it's urgent repairs or scheduled maintenance, we offer swift, reliable, 
        and expert service, complete with online consultations and seamless booking 
        options to keep you safe and your vision clear on the road.
        </p>
      </div>

      <div className="info-cards-content">
        <InformationCard
          title="Convenience Meets Excellence"
          description="Forget about the hassle of finding time for car maintenance. 
          Our Doorstep Car Glass Replacement service brings expert care directly to you. 
          Whether at home or work, our skilled technicians come equipped to replace your 
          car's glass with precision and efficiency, ensuring you get back on the road 
          swiftly, without compromising on quality or safety."
          icon={faTruckMedical}
        />

        <InformationCard
          title="Ease of Insurance Claims Processing"
          description="Handling insurance claims can be daunting, but not with Windshield Guru. 
          We offer comprehensive support for all types of insurance claims, ensuring a 
          smooth, hassle-free process. Our team works directly with your insurer to manage 
          the necessary paperwork and approvals, making your car glass repair or 
          replacement as seamless as possible."
          icon={faIdCard}
        />

        <InformationCard
          title="Protecting Your Journey Ahead"
          description="Your vehicle's safety isn't just about the glass it's equipped with; 
          it's also about having the right protection in place. At Windshield Guru, 
          we extend our services to help you renew or secure a new insurance policy. 
          Leveraging our network of insurance partners, we guide you through selecting 
          the best policy that covers your needs, ensuring you and your vehicle are 
          protected for the miles ahead."
          icon={faUserShield}
        />

      </div>
    </div>
  );
}

export default Info;
