import React from "react";
import LegalDocs from "../Components/LegalDocs";
// import PrivacyPolicy from "../Components/PrivacyPolicy";

function Legal() {
    return (
      <div>
        <LegalDocs />
        {/*<PrivacyPolicy/>*/}
      </div>
    );
  
}

export default Legal;
