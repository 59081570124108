import React from "react";
import PrivacyPolicyContent from "../Components/PrivacyPolicyContent";

function PrivacyPolicy() {
    return (
      <div>
        <PrivacyPolicyContent />
      </div>
    );
  
}

export default PrivacyPolicy;