import React from "react";
import Windshield from "../Assets/windshield guru van that sees everything.jpg";
import SolutionStep from "./SolutionStep";
import "../Styles/About.css";

function About() {
  return (
    <div className="about-section" id="about">
      <div className="about-image-content">
        <img src={Windshield} alt="windshield guru van that sees everything" className="about-image1" />
      </div>

      <div className="about-text-content">
        <h3 className="about-title">
          <span>About Us</span>
        </h3>
        <p className="about-description">
        Welcome to Windshield Guru, your premier destination for comprehensive and reliable car glass solutions. 
        Our skilled professionals specialize in windshield replacement and repair, catering to all vehicle models 
        with precision and care. Embrace the peace of mind that comes with clear vision and safety on the road. 
        Partner with us for an impeccable service experience, tailored to meet your needs. Embark on a journey 
        towards secure and unobstructed driving with Windshield Guru.
        </p>

        <h4 className="about-text-title">Your Glass Solutions</h4>

        <SolutionStep
          title="Select Your Glass Type"
          description="Identify the precise glass requirement for your vehicle with Windshield Guru. From windshields to door glasses, our extensive selection covers all your needs with top-quality materials."
        />

        <SolutionStep
          title="Schedule Your Service"
          description="Pick a convenient date and time for your service. Our flexible scheduling allows you to arrange repairs and replacements around your busy life, ensuring minimal disruption."
        />

        <SolutionStep
          title="Experience Expert Care"
          description="Our certified technicians utilize the latest techniques and tools to deliver exceptional service. Trust us to restore your vehicle’s glass to pristine condition, enhancing your safety and driving experience."
        />
      </div>
    </div>
  );
}

export default About;
