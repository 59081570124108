export const customerReviews = [
  {
    "name": "Aditya Kumar",
    "location": "Sector 47, Gurugram, Haryana, India",
    "message": "Windshield Guru made the windshield replacement on my car seamless and stress-free. Their doorstep service is a game-changer for busy individuals like me!"
  },
  {
    "name": "Priya Singh",
    "location": "Sohna Road, Gurugram, Haryana, India",
    "message": "Exceptional service and attention to detail. The technician was skilled and polite, and my car's glass looks as good as new. Highly recommend Windshield Guru."
  },
  {
    "name": "Rohan Gupta",
    "location": "DLF Phase 3, Gurugram, Haryana, India",
    "message": "I was amazed by the quick and efficient insurance claim handling by Windshield Guru. They took care of everything, making it hassle-free for me."
  },
  {
    "name": "Anjali Sharma",
    "location": "Palam Vihar, Gurugram, Haryana, India",
    "message": "The mobile windshield assessment service by Windshield Guru is fantastic. They evaluated the damage and provided a solution on the spot. Very convenient!"
  },
  {
    "name": "Vikram Yadav",
    "location": "Sector 56, Gurugram, Haryana, India",
    "message": "Truly satisfied with the custom glass solution for my vintage car. Windshield Guru's expertise in handling unique requirements is commendable."
  },
  {
    "name": "Sunita Reddy",
    "location": "MG Road, Gurugram, Haryana, India",
    "message": "The lifetime warranty on glass work by Windshield Guru shows their confidence in their service quality. It's reassuring to know my investment is protected."
  },
  {
    "name": "Amit Mehta",
    "location": "Golf Course Road, Gurugram, Haryana, India",
    "message": "24/7 emergency service is a lifesaver. Windshield Guru's prompt response and efficient repair work got me back on the road in no time after a late-night incident."
  },
  {
    "name": "Ritika Patel",
    "location": "New Colony, Gurugram, Haryana, India",
    "message": "The eco-friendly glass disposal practice by Windshield Guru is impressive. It's great to see a company that not only cares for customers but also for the environment."
  },
  {
    "name": "Karan Singh",
    "location": "Sector 14, Gurugram, Haryana, India",
    "message": "Windshield Guru's professional technitians restored my car's safety features perfectly after windshield replacement. Top-notch technology and service."
  },
  {
    "name": "Neha Arora",
    "location": "Sector 29, Gurugram, Haryana, India",
    "message": "Booking an appointment was incredibly easy, and the service exceeded my expectations. The team at Windshield Guru truly prioritizes customer satisfaction."
  }
]
;
