import React from 'react';
import whatsappIcon from '../Assets/whatsapp-icon.svg'; // Update path accordingly

const WhatsAppIcon = () => {
  return (
    <a href="https://wa.me/9310352465" // change phone number here 
       className="whatsapp-icon"
       target="_blank"
       rel="noopener noreferrer">
      <img src={whatsappIcon} alt="WhatsApp" />
    </a>
  );
};

export default WhatsAppIcon;
