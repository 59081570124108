import React , { useEffect } from "react";
import { Link } from "react-router-dom";
import "../Styles/PrivacyPolicy.css";

function PrivacyPolicyContent() {
    useEffect(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    });
    
    
    const structuredData = {
        "@context": "http://schema.org",
        "@type": "ProfessionalService",
        "name": "Windshield Guru",
        "url": "http://www.windshieldgurus.com",
        "logo": "http://www.windshieldgurus.com/logo.png",
        "sameAs": [
        "https://www.facebook.com/profile.php?id=100091143909888",
        "https://www.instagram.com/windshieldguru"
        ],
        "openingHours": "Mo,Tu,We,Th,Fr,Sa,Su 08:00-20:00",
        "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+91 9671032624",
        "contactType": "Customer Support",
        "areaServed": "IN",
        "availableLanguage": ["English", "Hindi"]
        }
    };



    return (
        <div className="privacy-section-title">
            <h1 className="privacy-siteTitle">
                <Link to="/">
                Windshield Guru 
                </Link>
            </h1>

            <div className="privacy-text-content">
                
                
                <p className="privacy-page-title">Privacy Policy</p>
                    <section>

                    <p className="privacy-title">Introduction</p>

                        <p className="privacy-description">Welcome to Windshield Guru, your trusted partner for all auto glass repair and replacement needs. 
                        Located in the heart of Gurugram, we are dedicated to providing you with top-notch services while 
                        respecting and protecting your privacy.</p>
                        
                        <p className="privacy-description">At Windshield Guru, we understand the importance of your personal data and are committed to 
                        protecting it with the utmost care and state-of-the-art security measures. This Privacy Policy outlines 
                        how we collect, use, process, and safeguard the information you provide us during your interaction 
                        with our website, <a href="http://www.windshieldgurus.com">www.windshieldgurus.com</a>, and our services.</p>
                        
                        <p className="privacy-description">Whether you are booking an appointment, making inquiries, or simply browsing our site, we want to ensure 
                        you understand our practices regarding data collection and usage. This policy applies to all visitors, 
                        users, and others who access our service ("Users").</p>
                        
                        <p className="privacy-description">By accessing or using our Service, you signify your agreement to this Privacy Policy. If you do not 
                        agree with any part of this policy, please do not use our Services.</p>
                        
                        
                        <p className="privacy-title">Key Commitments:</p>
                        <ul>
                            <li className="privacy-description"><strong>Transparency:</strong> We believe in being transparent about the data we collect and how we use it.</li>
                            <li className="privacy-description"><strong>Control:</strong> We give you control over the personal information you provide to us, including mechanisms to manage your preferences.</li>
                            <li className="privacy-description"><strong>Security:</strong> We are dedicated to securing your personal information and employ robust security measures to protect it.</li>
                            <li className="privacy-description"><strong>Integrity:</strong> We respect your privacy and are committed to using your personal information only in ways that are compatible with this Privacy Policy.</li>
                        </ul>
                        <p className="privacy-description">Please read the following policy to understand how your personal information will be treated as you make full use of our many offerings. Should you have any questions or concerns about this policy or our practices, feel free to <a href="#">contact us</a> anytime.</p>
                    </section>

                    

                    <section>
                        <p className="privacy-title">Information We Collect</p>
                        <p className="privacy-description">At Windshield Guru, we are committed to safeguarding your privacy. This section outlines 
                        the types of information we may collect from you when you use our services, including when 
                        you visit our website at <a href="http://www.windshieldgurus.com">www.windshieldgurus.com</a>.</p>
                        
                        <p className="privacy-title">Information You Provide</p>
                        <p className="privacy-description">Some services on our website require you to provide us with personal information. This may include:</p>
                        <ul>
                            <li className="privacy-description"><strong>Contact Information:</strong> such as your name, email address, phone number, your vehicle data and your address when you book an appointment or request a service.</li>
                            <li className="privacy-description"><strong>Payment Information:</strong> necessary for processing transactions, though note that we use secure third-party payment processors and do not store your credit card information directly.</li>
                            <li className="privacy-description"><strong>Feedback and Communication:</strong> any information you provide when you communicate with us for support, feedback, or inquiries.</li>
                        </ul>
                        
                        <p className="privacy-title">Information Collected Automatically</p>
                        <p className="privacy-description">In addition to the information you provide, we may also collect information automatically through our website and services, including:</p>
                        <ul>
                            <li className="privacy-description"><strong>Usage Information:</strong> Details of your interactions with our website and services, including the pages you visit, the time spent on those pages, and the services you use.</li>
                            <li className="privacy-description"><strong>Technical Information:</strong> Information about the device and browser you use to access our website, including IP address, device type, operating system, and browser type.</li>
                            <li className="privacy-description"><strong>Cookies and Tracking Technologies:</strong> We may use cookies, beacons, and similar technologies to improve your experience, analyze trends, and gather demographic information about our user base as a whole.</li>
                        </ul>
                        
                        <p className="privacy-description">The collection of this information helps us to improve our services, understand user needs, and enhance the user experience on our website.</p>
                    </section>


                    <section>
                        <p className="privacy-title">How We Use Your Information</p>
                        <p className="privacy-description">We at Windshield Guru take your privacy seriously. The information we collect 
                        serves multiple purposes, all aimed at enhancing the services we provide and ensuring a 
                        seamless experience for you. Below are the key ways we utilize the information you share with us:</p>

                        <p className="privacy-title">Providing Services</p>
                        <p className="privacy-description">We use your information to fulfill service requests, process payments, communicate with 
                        you regarding appointments, and provide customer support. This includes using contact and payment 
                        information to complete transactions and service bookings.</p>

                        <p className="privacy-title">Improving Our Services</p>
                        <p className="privacy-description">Feedback, usage data, and interaction information help us understand your needs better, 
                        allowing us to improve our website's functionality, service offerings, and customer support. 
                        Analyzing how you use our services guides us in making them more intuitive and user-friendly.</p>

                        <p className="privacy-title">Marketing and Communication</p>
                        <p className="privacy-description">With your consent, we may use your contact information to send you updates about new services, 
                        special offers, and promotions. We believe in keeping our customers informed but also respect your 
                        choice to opt-out of such communications at any time.</p>

                        <p className="privacy-title">Security and Fraud Prevention</p>
                        <p className="privacy-description">The safety of your personal information is a top priority. We use collected data to protect 
                        against unauthorized access, disclosure, alteration, and destruction of personal information stored in our systems. 
                        This includes implementing data security measures and fraud detection mechanisms.</p>

                        <p className="privacy-title">Legal Compliance</p>
                        <p className="privacy-description">Occasionally, we may need to use your information to comply with legal obligations, court orders, 
                        or in response to lawful requests by public authorities, including meeting national security or law enforcement requirements.</p>

                        <p className="privacy-description">Understanding how we use your information is crucial. We strive for transparency in all our operations and 
                        want you to feel secure and informed about the choices you make with Windshield Guru.</p>
                    </section>



                    <section>
                        <p className="privacy-title">Sharing Your Information</p>
                        <p className="privacy-description">At Windshield Guru, we respect your privacy and are committed to protecting the information you 
                        share with us. We understand the importance of maintaining the confidentiality of your personal data. 
                        Here's how we handle the sharing of your information:</p>

                        <p className="privacy-title">Service Providers</p>
                        <p className="privacy-description">We may share your information with third-party service providers who perform services on our behalf, 
                        such as payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance. 
                        These service providers are authorized to use your personal information only as necessary to provide these services to us.</p>

                        <p className="privacy-title">Compliance with Laws</p>
                        <p className="privacy-description">We may disclose your information as required by law, such as to comply with a subpoena, or 
                        similar legal process when we believe in good faith that disclosure is necessary to protect our rights, 
                        protect your safety or the safety of others, investigate fraud, or respond to a government request.</p>

                        <p className="privacy-title">Business Transfers</p>
                        <p className="privacy-description">If Windshield Guru is involved in a merger, acquisition, or sale of all or a portion of its assets, 
                        your personal information may be transferred as part of that transaction. We will notify you via email 
                        and/or a prominent notice on our website of any change in ownership or uses of your personal information, 
                        as well as any choices you may have regarding your personal information.</p>

                        <p className="privacy-title">With Your Consent</p>
                        <p className="privacy-description">We may share your information with third parties with your express consent. Apart from this, we do 
                        not sell, rent, or trade your personal information to third parties.</p>

                        <p className="privacy-description">Our goal is to ensure the highest level of security and confidentiality. Should you have any questions 
                        about how we share your information, please feel free to <a href="windshieldguruinfo@gmail.com">contact us</a>.</p>
                    </section>


                    <section>
                        <p className="privacy-title">Your Privacy Rights</p>
                        <p className="privacy-description">At Windshield Guru, we are committed to ensuring that your privacy is protected and respected. 
                        We provide you with the following rights regarding your personal information:</p>

                        <p className="privacy-title">Access to Your Information</p>
                        <p className="privacy-description">You have the right to request a copy of the personal information we hold about you. 
                        This enables you to receive a copy of the personal data we have about you and to check that we are lawfully processing it.</p>

                        <p className="privacy-title">Correction of Your Information</p>
                        <p className="privacy-description">If you believe that any information we have on file is incorrect or incomplete, 
                        you have the right to request us to correct or complete this information.</p>

                        <p className="privacy-title">Deletion of Your Information</p>
                        <p className="privacy-description">You have the right to request that Windshield Guru delete or remove your personal information 
                        when there is no good reason for us continuing to process it.</p>

                        <p className="privacy-title">Object to Processing</p>
                        <p className="privacy-description">You have the right to object to the processing of your personal data where we are relying on a 
                        legitimate interest (or those of a third party) and there is something about your particular situation 
                        which makes you want to object to processing on this ground.</p>

                        <p className="privacy-title">Restriction of Processing</p>
                        <p className="privacy-description">You have the right to request the restriction of processing of your personal information. 
                        This enables you to ask us to suspend the processing of personal information about you, 
                        for example if you want us to establish its accuracy or the reason for processing it.</p>

                        <p className="privacy-title">Data Portability</p>
                        <p className="privacy-description">You have the right to request the transfer of your personal data to another party, under certain conditions.</p>

                        <p className="privacy-description">We are dedicated to upholding these rights and ensuring you can fully exercise them. 
                        If you wish to exercise any of the rights set out above, please <a href="#">contact us</a>.</p>

                        <p className="privacy-description">Note that we may need to request specific information from you to help us confirm your identity 
                        and ensure your right to access your personal data (or to exercise any of your other rights). 
                        This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it.</p>
                    </section>


                    <section>
                        <p className="privacy-title">Data Security</p>
                        <p className="privacy-description">At Windshield Guru, safeguarding your personal information is our top priority. 
                        We implement a variety of security measures to ensure the protection of your personal data against unauthorized access, 
                        alteration, disclosure, or destruction. Our commitment to data security includes:</p>

                        <p className="privacy-title">Advanced Security Technologies</p>
                        <p className="privacy-description">We employ state-of-the-art security technologies and procedures to protect your personal information. 
                        This includes the use of secure server software (SSL), encryption, firewalls, and secure data centers to host our services.</p>

                        <p className="privacy-title">Strict Data Access Controls</p>
                        <p className="privacy-description">Access to your personal data is strictly controlled and limited to authorized personnel only. 
                        Employees who have access to personal information are required to adhere to our privacy policies and undergo 
                        regular training on data protection and security.</p>

                        <p className="privacy-title">Regular Security Audits</p>
                        <p className="privacy-description">We conduct regular security audits and checks to ensure our protective measures remain effective and up-to-date. 
                        This proactive approach allows us to identify potential vulnerabilities and implement corrective actions swiftly.</p>

                        <p className="privacy-title">Compliance with Regulations</p>
                        <p className="privacy-description">We comply with applicable data protection laws and regulations, ensuring our security 
                        practices meet legal standards and industry best practices.</p>

                        <p className="privacy-title">In the Event of a Data Breach</p>
                        <p className="privacy-description">In the unlikely event of a data breach, Windshield Guru is committed to responding promptly and effectively. 
                        We have a breach response protocol in place, which includes:</p>
                        <ul>
                            <li className="privacy-description">Immediate investigation to identify and contain the breach</li>
                            <li className="privacy-description">Notification to affected individuals and relevant authorities in accordance with applicable laws</li>
                            <li className="privacy-description">Review and reinforcement of our security measures to prevent future breaches</li>
                        </ul>
                        <p className="privacy-description">Protecting your privacy and securing your data is a responsibility we take seriously. 
                        While we strive to use commercially acceptable means to protect your personal information, no method of 
                        transmission over the Internet, or method of electronic storage, is 100% secure. 
                        Therefore, we cannot guarantee its absolute security.</p>

                        <p className="privacy-description">If you have any questions about our security practices or if you have concerns about the security of your 
                        information, please <a href="#">contact us</a>.</p>
                    </section>



                    <section>
                        <p className="privacy-title">Children's Privacy</p>
                        <p className="privacy-description">Windshield Guru recognizes the importance of protecting the privacy and safety of children. 
                        Our services are not designed for, nor do we knowingly collect information from, children under the age of 18 years. 
                        We are committed to complying with the Children's Online Privacy Protection Act (COPPA) and all other applicable laws 
                        and regulations concerning children's privacy.</p>

                        <p className="privacy-title">Collection of Information from Children</p>
                        <p className="privacy-description">We do not knowingly solicit or collect personal information from children under the age of 18. If we become aware 
                        that a child under 18 has provided us with personal information without parental consent, we take steps to remove such 
                        information and terminate the child's account.</p>

                        <p className="privacy-title">Parental Consent and Involvement</p>
                        <p className="privacy-description">We strongly encourage parents and guardians to take an active role in their children's online activities. 
                        Parents who believe that their child has submitted personal information to us without their consent should 
                        contact us immediately. We will work diligently to address the issue, including removing the information from our 
                        records and terminating any account the child may have created.</p>

                        <p className="privacy-title">Use of Our Services by Minors</p>
                        <p className="privacy-description">Our website and services are directed to individuals who are at least 18 years of age or older. 
                        We do not knowingly engage in transactions or interactions with children under the age of 18.</p>

                        <p className="privacy-description">If you have any concerns about your child's privacy in relation to our services, or if you believe that your 
                        child has provided us with their personal information, please <a href="#">contact us</a> immediately. 
                        We are dedicated to protecting the privacy of all our users, especially that of children.</p>
                    </section>


                    <section>
                        <p className="privacy-title">Changes to This Policy</p>
                        <p className="privacy-description">Windshield Guru reserves the right to update or modify this Privacy Policy at any time and for any reason. 
                        Any changes or modifications will be effective immediately upon posting the revised Privacy Policy on our website. 
                        We will take reasonable steps to inform you of any significant changes to our Privacy Policy. 
                        This may include sending a notice to the primary email address specified in your account or posting a prominent notice on our site.</p>

                        <p className="privacy-description">We encourage you to periodically review this page for the latest information on our privacy practices. 
                        Your continued use of our website and services following the posting of changes to these terms will mean you accept those changes.</p>

                        <p className="privacy-description">If you have any questions regarding our Privacy Policy or the practices of this site, please <a href="#">contact us</a>. 
                        By using Windshield Guru's services, you consent to our Privacy Policy and agree to its terms.</p>
                    </section>


                    <section>
                        <p className="privacy-title">Contact Us</p>
                        <p className="privacy-description">If you have any questions about this Privacy Policy, the practices of this site, or your dealings with Windshield Guru, please contact us at:</p>
                        
                        <ul>
                            <li className="privacy-description"><strong>Email:</strong> <a href="mailto:windshieldguruinfo@gmail.com">windshieldguruinfo@gmail.com</a></li>
                            <li className="privacy-description"><strong>Phone:</strong> +91 9671032624</li>
                            <li className="privacy-description"><strong>Address:</strong> Windshield Guru, 31A, Sector 33, Near Hero Honda Chowk, Gurugram, Haryana, 122001, India</li>
                        </ul>
                        
                        <p className="privacy-description">We are committed to resolving complaints about your privacy and our collection or use of your personal information. 
                        For inquiries or complaints regarding this Privacy Policy, please contact us first. We will strive to respond to your 
                        inquiries and resolve any concerns regarding your privacy promptly.</p>
                        
                        <p className="privacy-description">For more detailed information on our privacy practices or if you need further assistance regarding your rights, 
                        please do not hesitate to reach out to us. Our dedicated team is here to ensure your experience with Windshield Guru is secure, 
                        respectful, and satisfactory.</p>
                    </section>

                    <div className="privacy-footer">
                        <p>© 2024 Windshield Guru. All rights reserved.</p>
                    </div>


                {/* Embedding JSON-LD structured data */}
                <script type="application/ld+json">
                    {JSON.stringify(structuredData)}
                </script>
            </div>
        </div>
    );
    
};

export default PrivacyPolicyContent;
