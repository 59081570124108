import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../Styles/AppointmentForm.css";
import { ToastContainer, toast } from "react-toastify";

function AppointmentForm() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

  }, []);
  

  const [customerName, setCustomerName] = useState("");
  const [carMake, setCarMake] = useState("");
  const [carModel, setCarModel] = useState("");
  const [glassDescription, setGlassDescription] = useState("");
  const [customerNumber, setCustomerNumber] = useState("");
  const [appointmentTime, setAppointmentTime] = useState("");
  const [preferredMode, setPreferredMode] = useState("default");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    setFormErrors(false); // Reset error state
    window.scrollTo(0, 0);
  

    try {
      // Attempt to book the appointment here
      // This is where you'd call an API or some asynchronous logic
      // If unsuccessful, you can set an error state to show the message

      // Validate form inputs
    
      const errors = {};
      if (!customerName.trim()) {
        errors.customerName = "Name is required";
      } else if (customerName.trim().length < 4) {
        errors.customerName = "Name must be at least 4 characters";
      }
  
      if (!carMake.trim()) {
        errors.carMake = "Car Make is required";
      } 
  
      if (!carModel.trim()) {
        errors.carModel = "Car Model is required";
      } 
  
      if (!glassDescription.trim()) {
        errors.glassDescription = "Plese mention which glass is damaged eg. front windshield";
      } else if (glassDescription.trim().length < 8) {
        errors.glassDescription = "Glass Description must be atleast 8 characters";
      }
  
  
      if (!customerNumber.trim()) {
        errors.customerNumber = "Your phone number is required";
      } else if (customerNumber.trim().length !== 10) {
        errors.customerNumber = "Your phone number must be of 10 digits";
      }
  
    
      if (!appointmentTime) {
        errors.appointmentTime = "Appointment time is required";
      } else {
        const selectedTime = new Date(appointmentTime).getTime();
        const currentTime = new Date().getTime();
        if (selectedTime <= currentTime) {
          errors.appointmentTime = "Please select a future appointment time";
        }
      }
      if (preferredMode === "default") {
        errors.preferredMode = "Please select preferred mode";
      }
  
      if (Object.keys(errors).length > 0) {
        setFormErrors(errors);
        return;
      }
  
      // Reset form fields and errors after successful submission
      setCustomerName("");
      setCarMake("");
      setCarModel("");
      setGlassDescription("");
      setCustomerNumber("");
      setAppointmentTime("");
      setPreferredMode("default");
      setFormErrors({});
  
      toast.success("Appointment Scheduled ! Someone from our team will contact you within the next 30 minutes.", {
        position: toast.POSITION.TOP_CENTER,
        onOpen: () => setIsSubmitted(true),
        onClose: () => setIsSubmitted(false),
      });
    
  
  
  
    // appending data to google spreadsheets
    const formData = new FormData();
    formData.append("entry.1799184167", customerName);
    formData.append("entry.573499338", carMake);
    formData.append("entry.1568106528", carModel);
    formData.append("entry.220959829", glassDescription);
    formData.append("entry.1613767831", customerNumber);
    formData.append("entry.1547439702", appointmentTime);
    formData.append("entry.1006671356", preferredMode);
  
    // Fetch API to submit form data to Google Forms
    await fetch("https://docs.google.com/forms/u/0/d/e/1FAIpQLSc9wqjaciU7zTsIDtOiPBSQ3lqi8KRyBjz9Kelopqbk03UNUg/formResponse", {
      method: "POST",
      body: formData,
      mode: 'no-cors', // This prevents CORS errors
    })
        toast.success("Appointment Scheduled ! Someone from our team will contact you within the next 30 minutes.", { 
          position: toast.POSITION.TOP_CENTER});
          
        
        // Reset form and state as needed
      
    
} catch (error) {
  console.error("Error submitting form", error);
  // Here, instead of formErrors(true), you might show a toast message or update a dedicated submissionError state
  toast.error("There was an issue scheduling your appointment. Please try again.", {
    position: toast.POSITION.TOP_CENTER,
  });
}
};

  

    




  return (
    
    <div className="appointment-form-section">
      <h1 className="legal-siteTitle">
        <Link to="/">
          Windshield Guru
        </Link>
      </h1>

      <div className="form-container">
        <h2 className="form-title">
          <span>Book Appointment Online</span>
        </h2>

        <form className="form-content" onSubmit={handleSubmit}>
          <label>
            Your Name:
            <input
              type="text"
              value={customerName}
              onChange={(e) => setCustomerName(e.target.value)}
              required
            />
            {formErrors.customerName && <p className="error-message">{formErrors.customerName}</p>}
          </label>



          {/* car make field is added here. string input is expected */}
          <br />
          <label>
            Car Make:
            <input
              type="text"
              value={carMake}
              onChange={(e) => setCarMake(e.target.value)}
              required
            />
            {formErrors.carMake && <p className="error-message">{formErrors.carMake}</p>}
          </label>


          

          {/* car model field is added here. string input is expected */}
          <br />
          <label>
            Car Model:
            <input
              type="text"
              value={carModel}
              onChange={(e) => setCarModel(e.target.value)}
              required
            />
            {formErrors.carModel && <p className="error-message">{formErrors.carModel}</p>}
          </label>
          

          {/* car glass description field is added here. string input is expected */}
          <br />
          <label>
            Damaged Glass Description:
            <input
              type="text"
              value={glassDescription}
              onChange={(e) => setGlassDescription(e.target.value)}
              required
            />
            {formErrors.glassDescription && <p className="error-message">{formErrors.glassDescription}</p>}
          </label>




          <br /> 
          <label>
            Your Phone Number:
            <input
              type="text"
              value={customerNumber}
              onChange={(e) => setCustomerNumber(e.target.value)}
              required
            />
            {formErrors.customerNumber && <p className="error-message">{formErrors.customerNumber}</p>}
          </label>

          

          <br />
          <label>
            Preferred Appointment Time:
            <input
              type="datetime-local"
              value={appointmentTime}
              onChange={(e) => setAppointmentTime(e.target.value)}
              required
            />
            {formErrors.appointmentTime && <p className="error-message">{formErrors.appointmentTime}</p>}
          </label>

          <br />
          <label>
            Preferred Mode:
            <select
              value={preferredMode}
              onChange={(e) => setPreferredMode(e.target.value)}
              required
            >
              <option value="default">Select</option>
              <option value="Doorstep Service">Doorstep Service</option>
              <option value="Workshop Visit">Workshop Visit</option>
            </select>
            {formErrors.preferredMode && <p className="error-message">{formErrors.preferredMode}</p>}
          </label>

          <br />
          <button type="submit" className="text-appointment-btn">
            Confirm Appointment
          </button>

          <p className="success-message" style={{display: isSubmitted ? "block" : "none"}}>Appointment details will be sent to your phone number via SMS shortly.</p>
        </form>
      </div>

      <div className="legal-footer">
        <p>© 2024 Windshield Guru. All rights reserved.</p>
      </div>

      <ToastContainer autoClose={5000} limit={1} closeButton={false} />
    </div>
    
  );
}

export default AppointmentForm;
