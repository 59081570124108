import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home";
import Legal from "./Pages/Legal";
import NotFound from "./Pages/NotFound";
import Appointment from "./Pages/Appointment";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import General from "./Pages/General";
import BlogPage from './Pages/Blog'; // Your blog listing page
import BlogPost from './Components/BlogPost'; // Your individual blog post page
import WhatsAppIcon from './Components/WhatsAppIcon'; // Update the import path accordingly
import './Styles/Whatsapp.css'; // Ensure your CSS file is imported

function App() {
  return (
    <div className="App">
      <Router >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/general" element={<General />} />
          <Route path="/appointment" element={<Appointment />} />  
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog/:slug" element={<BlogPost />} />      
          <Route path="*" element={<NotFound />} />
        </Routes>

      </Router>
      <WhatsAppIcon />
    </div>
  );
}

export default App;
