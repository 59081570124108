import React, { useState, useEffect } from 'react';
import "../Styles/check_price.css";

// Example JSON data
const car_data = {
  "Maruti": {
    "800": {
      "Tempered Windshield": 1924,
      "Laminated Windshield": 2704,
      "Front Door Glass": 702,
      "Rear Door Glass": 728,
      "Backscreen": 1248,
      "Defogger Backsceen": 1456
    },
    "Omni": {
      "Tempered Windshield": 1508,
      "Laminated Windshield": 2392,
      
      "Front Door Glass": 614,
      "Backscreen": 1274
    },
    "Gypsy": {
      "Tempered Windshield": 1144,
      "Laminated Windshield": 2496,
      "Front Door Glass": 546,
      "Backscreen": 1144
    },
    "Zen": {
      "Laminated Windshield": 3744,
      "Front Door Glass": 702,
      "Rear Door Glass": 728,
      "Rear Quater": 452,
      "Backscreen (Old)": 1300,
      "Defogger Backsceen (Old)": 1664,
      "Backscreen (Modified)": 1248,
      "Defogger Backsceen (Modified)": 1976
    },
    "Esteem": {
      "Laminated Windshield": 4576,
      "Front Door Glass": 1196,
      "Rear Door Glass": 686,
      "Backscreen": 1950,
      "Quarter Glass with Hole": 400,
      "Quarter Glass without Hole": 400,
      "Defogger Backscreen": 2262
    },
    "Baleno": {
      "Laminated Windshield": 5200,
      "Front Door Glass": 780,
      "Rear Door Glass": 754,
      "Backscreen": 1872,
      "Defogger Backscreen": 2600
    },
    "Baleno (2015)": {
      "Laminated Windshield": 4420,
      "Front Door Glass": 1310,
      "Rear Door Glass": 988,
      "Rear Quarter with SUS": 1560,
      "Rear Quarter without SUS": 1144,
      "Front Door Dark Green": 2236,
      "Rear Door Dark Green": 1664,
      "Backscreen": 2756,
      "Defogger Backscreen": 3536
    },
    "Baleno (2022)": {
      "Laminated Windshield": 4368,
      "Front Door Glass": 967,
      "Rear Door Glass": 702,
      "Rear Quarter with SUS": 936,
      "Rear Quarter without SUS": 690,
      "Front Door Dark Green": 1336,
      "Rear Door Dark Green": 1290,
      "Defogger Backscreen with wiper hole": 2782,
      "Defogger Backscreen without wiper hole": 2548,
      "Rear Quarter Dark Green with SUS": 1227
    },
    "Fronx": {
      "Laminated Windshield": 4368,
      "Front Door Glass": 967,
      "Rear Door Glass": 702,
      "Rear Quarter with SUS": 1196,
      "Rear Quarter without SUS": 832,
      "Front Door Dark Green": 1336,
      "Rear Door Dark Green": 1290,
      "Defogger Backscreen with wiper hole": 3016,
      "Defogger Backscreen without wiper hole": 2912,
      "Rear Quarter Dark Green with SUS": 1404
    },
    "WagonR before 2010": {
      "Laminated Windshield": 3796,
      "Front Door Glass": 1014,
      "Rear Door Glass": 858,
      "Rear Quarter": 478,
      "Front Quarter": 166,
      "Backscreen": 1612,
      "Defogger Backscreen": 1976,
      "Defogger Backscreen modified": 2184,
      "Backscreen modified": 1586
    },
    "WagonR 2010 to 2017": {
      "Laminated Windshield": 3172,
      "Front Door Glass": 806,
      "Rear Door Glass": 556,
      "Rear Quarter": 260,
      "Front Quarter": 208,
      "Backscreen": 1638,
      "Defogger Backscreen": 2496
    },
    "New WagonR 2018 to 2024": {
      "Laminated Windshield": 4004,
      "Front Door Glass": 936,
      "Rear Door Glass": 806,
      "Backscreen": 2288,
      "Defogger Backscreen": 3484
    },
    "Alto Old": {
      "Laminated Windshield": 3016,
      "Front Door Glass": 806,
      "Rear Door Glass": 676,
      "Backscreen": 1248,
      "Defogger Backscreen": 1560
    },
    "Alto K10 old": {
      "Laminated Windshield": 3016,
      "Front Door Glass": 806,
      "Rear Door Glass": 676,
      "Backscreen": 1872,
      "Defogger Backscreen": 1872
    },
    "Alto 800 old": {
      "Laminated Windshield": 3120,
      "Front Door Glass": 780,
      "Rear Door Glass": 806,
      "Rear Quarter": 224,
      "Backscreen": 1508,
      "Defogger Backscreen": 2070
    },
    "Alto K10 2015 to 2018": {
      "Laminated Windshield": 3120,
      "Front Door Glass": 780,
      "Rear Door Glass": 806,
      "Rear Quarter": 224,
      "Backscreen": 1742,
      "Defogger Backscreen": 2548
    },
    "Alto 800 2019": {
      "Laminated Windshield": 4134,
      "Front Door Glass": 780,
      "Rear Door Glass": 806,
      "Rear Quarter": 224,
      "Backscreen": 1508,
      "Defogger Backscreen": 2070
    },
    "Alto 2022": {
      "Laminated Windshield": 3588,
      "Front Door Glass": 988,
      "Rear Door Glass": 780,
      "Backscreen": 2080
    },
    "S Presso": {
      "Laminated Windshield": 3536,
      "Front Door Glass": 1134,
      "Rear Door Glass": 806,
      "Backscreen": 2704,
      "Defogger Backscreen": 2994
    },
    "Versa/Eeco": {
      "Laminated Windshield": 3224,
      "Front Door Glass": 754,
      "Rear Door Glass": 614,
      "Side Window": 426,
      "Backscreen": 1248
    },
    "Swift Old": {
      "Laminated Windshield": 3900,
      "Front Door Glass": 900,
      "Rear Door Glass": 624,
      "Rear Quarter": 208,
      "Backscreen": 1586,
      "Defogger Backscreen": 2184
    },
    "Swift Dzire Old": {
      "Laminated Windshield": 3900,
      "Front Door Glass": 900,
      "Rear Door Glass": 478,
      "Rear Quarter": 302,
      "Backscreen": 2080,
      "Defogger Backscreen": 2496
    },
    "Swift 2011 to 2016": {
      "Laminated Windshield": 3926,
      "Front Door Glass": 926,
      "Rear Door Glass": 530,
      "Rear Quarter": 239,
      "Backscreen": 1976,
      "Defogger Backscreen": 2756
    },
    "Swift Dzire 2012 to 2017": {
      "Laminated Windshield": 3926,
      "Front Door Glass": 926,
      "Rear Door Glass": 530,
      "Rear Quarter": 239,
      "Backscreen": 2054,
      "Defogger Backscreen": 2704
    },
    "Swift Dzire New 2017": {
      "Laminated Windshield": 4108,
      "Front Door Glass": 1170,
      "Rear Door Glass": 614,
      "Rear Quarter": 296,
      "Backscreen": 3120,
      "Defogger Backscreen": 3744
    },
    "Swift New 2018": {
      "Laminated Windshield": 3874,
      "Front Door Glass": 1045,
      "Rear Door Glass": 541,
      "Backscreen": 2392,
      "Defogger Backscreen": 3224
    },
    "Ertiga Old": {
      "Laminated Windshield": 4992,
      "Front Door Glass": 702,
      "Rear Door Glass": 702,
      "Backscreen": 3276,
      "Defogger Backscreen": 3640
    },
    "Ertiga New 2018": {
      "Laminated Windshield": 5928,
      "Front Door Glass": 858,
      "Rear Door Glass": 962,
      "Side Window": 1430,
      "Backscreen": 3276,
      "Defogger Backscreen": 3640
    },
    "XL6": {
      "Laminated Windshield": 5928,
      "Front Door Glass": 858,
      "Rear Door Glass": 962,
      "Side Window": 1430,
      "Backscreen": 3276,
      "Defogger Backscreen": 3640
    },
    "SX4": {
      "Laminated Windshield": 5928,
      "Front Door Glass": 905,
      "Rear Door Glass": 780,
      "Rear Quarter": 276,
      "Front Vent": 1326,
      "Defogger Backscreen": 4160
    },
    "Zen Estilo": {
      "Laminated Windshield": 3744,
      "Front Door Glass": 884,
      "Rear Door Glass": 806,
      "Rear Quarter": 494,
      "Front Quarter Encapsulated": 1113,
      "Defogger Backscreen (Modified)": 2158,
      "Defogger Backscreen": 2158
    },
    "A Star": {
      "Laminated Windshield": 4108,
      "Front Door Glass": 884,
      "Rear Door Glass": 572,
      "Rear Quarter": 265,
      "Backscreen": 1560,
      "Defogger Backscreen": 1872
    },
    "Ritz": {
      "Laminated Windshield": 3692,
      "Front Door Glass": 754,
      "Rear Door Glass": 582,
      "Rear Quarter": 302,
      "Front Vent": 260,
      "Backscreen without wiper hole": 1612,
      "Backscreen with wiper hole": 1872,
      "Defogger Backscreen": 2179
    },
    "Jimny": {
      "Front Door Glass": 988,
      "Rear Door Glass": 624,
      "Rear Quarter": 354,
      "Side Window": 546,
      "Backscreen": 2184,
      "Front Door Dark Green": 1976,
      "Rear Door Dark Green": 1144,
      "Rear Quarter Dark Green": 572,
      "Side Window Dark Green": 936
    },
    "Celerio Old": {
      "Laminated Windshield": 3458,
      "Front Door Glass": 1144,
      "Rear Door Glass": 650,
      "Rear Quarter": 317,
      "Backscreen": 2340,
      "Defogger Backscreen": 3328
    },
    "Celerio New": {
      "Laminated Windshield": 4264,
      "Front Door Glass": 936,
      "Rear Door Glass": 754,
      "Backscreen": 2028,
      "Defogger Backscreen": 2496
    },
    "Ciaz": {
      "Laminated Windshield": 5460,
      "Front Door Glass": 1336,
      "Rear Door Glass": 910,
      "Rear Quarter": 572,
      "Defogger Backscreen": 3848
    },
    "Carry": {
      "Laminated Windshield": 3172,
      "Front Door Glass": 676,
      "Rear Fix Window": 260,
      "Rear Sliding Window": 348
    },
    "S Cross": {
      "Laminated Windshield": 5564,
      "Front Door Glass": 1118,
      "Rear Door Glass": 962,
      "Rear Quarter": 338,
      "Front Vent": 260,
      "Side Window": 614
    },
    "Vitara Brezza": {
      "Laminated Windshield": 5044,
      "Laminated Windshield Rain Sensor": 5200,
      "Front Door Glass": 1014,
      "Rear Door Glass": 780,
      "Rear Quarter": 1352,
      "Backscreen": 1872,
      "Defogger Backscreen without wiper hole": 2652,
      "Defogger Backsceen with wiper hole": 2600
    },
    "Brezza 2022": {
      "Laminated Windshield": 5200,
      "Front Door Glass": 988,
      "Rear Door Glass": 728,
      "Side Window": 1664,
      "Backscreen": 2392,
      "Defogger Backscreen without wiper hole": 3276,
      "Defogger Backsceen with wiper hole": 3276
    },
    "Grand Vitara (2022)": {
      "Laminated Windshield": 6656,
      "Front Door Glass": 1040,
      "Rear Door Glass": 936,
      "Front Door Dark Green": 1872,
      "Rear Door Dark Green": 1560,
      "Rear Quarter without SUS": 1144,
      "Rear Quarter with SUS": 1222,
      "Rear Quarter Dark Green with SUS": 1378,
      "Defogger Backscreen without wiper hole": 3328,
      "Defogger Backsceen with wiper hole": 3411
    },
    "Ignis": {
      "Laminated Windshield": 4264,
      "Front Door Glass": 1030,
      "Rear Door Glass": 608,
      "Rear Quarter": 328,
      "Backscreen": 2652,
      "Defogger Backscreen": 3536
    }
  },
  "Hyundai": {
    "Verna 2012": {
      "Laminated Windshield": 5096,
      "Front Door Glass": 1227,
      "Rear Door Glass": 806,
      "Rear Quarter": 250,
      "Defogger Backscreen": 3120
    },
    "Verna Fluidic 2012": {
      "Laminated Windshield": 3744,
      "Laminated Windshield with Modified Rain Sensor": 4732,
      "Front Door Glass": 1144,
      "Rear Door Glass": 806,
      "Backscreen": 2912,
      "Defogger Backscreen": 3328
    },
    "Verna Fluidic 2017": {
      "Laminated Windshield": 3692,
      "Front Door Glass": 1144,
      "Rear Door Glass": 1040,
      "Defogger Backscreen": 2808
    },
    "Accent": {
      "Laminated Windshield": 3692,
      "Front Door Glass": 884,
      "Rear Door Glass": 582,
      "Rear Quarter": 380,
      "Backscreen": 2288,
      "Defogger Backscreen": 2730
    },
    "Eon": {
      "Laminated Windshield": 2964,
      "Front Door Glass": 832,
      "Rear Door Glass": 884,
      "Backscreen": 1612
    },
    "Getz": {
      "Laminated Windshield": 5096,
      "Front Door Glass": 1175,
      "Rear Door Glass": 676,
      "Rear Quarter": 276,
      "Backscreen": 2184,
      "Defogger Backscreen without wiper hole": 2704,
      "Defogger Backscreen with wiper hole": 2600
    },
    "I10 Old": {
      "Laminated Windshield": 3744,
      "Front Door Glass": 905,
      "Rear Door Glass": 556,
      "Rear Quarter": 276,
      "Backsceen": 2054,
      "Defogger Backscreen": 2288
    },
    "I10 Grand": {
      "Laminated Windshield": 3120,
      "Front Door Glass": 1352,
      "Rear Door Glass": 936,
      "Rear Quarter": 380,
      "Backsceen": 1976,
      "Defogger Backscreen without wiper hole": 3744,
      "Defogger Backscreen with wiper hole": 3640
    },
    "Xcent": {
      "Laminated Windshield": 3120,
      "Front Door Glass": 1352,
      "Rear Door Glass": 936,
      "Rear Quarter": 354,
      "Backsceen": 1664,
      "Defogger Backscreen": 3016
    },
    "I20 Old": {
      "Laminated Windshield": 4368,
      "Laminated Windshield with rain sensor": 6812,
      "Front Door Glass": 1123,
      "Rear Door Glass": 858,
      "Rear Quarter": 286,
      "Defogger Backscreen": 2704
    },
    "I20 2020": {
      "Laminated Windshield": 3120,
      "Front Door Glass": 936,
      "Rear Door Glass": 624,
      "Rear Quarter": 1862,
      "Backsceen without wiper hole": 1872,
      "Defogger Backscreen with wiper hole": 3224
    },
    "Hyundai Elantra": {
      "Laminated Windshield": 9568
    },
    "Santro Xing": {
      "Laminated Windshield": 3120,
      "Front Door Glass": 676,
      "Rear Door Glass": 910,
      "Rear Quarter": 494,
      "Backsceen without wiper hole": 1768,
      "Backscreen with wiper hole": 2496,
      "Defogger Backscreen with wiper hole": 2808,
      "Defogger Backscreen without wiper hole": 2808
    },
    "Santro New": {
      "Laminated Windshield": 4368,
      "Front Door Glass": 884,
      "Rear Door Glass": 832
    },
    "I10 Grand Nios": {
      "Laminated Windshield": 3432,
      "Front Door Glass": 884,
      "Rear Door Glass": 936,
      "Rear Quarter": 265,
      "Backsceen without wiper hole": 2184,
      "Defogger Backscreen with wiper hole": 2600,
      "Defogger Backscreen without wiper hole": 2392
    },
    "Aura": {
      "Laminated Windshield": 3432,
      "Front Door Glass": 884,
      "Rear Door Glass": 936,
      "Rear Quarter": 276,
      "Defogger Backscreen without wiper hole": 2600
    },
    "Sonata": {
      "Laminated Windshield": 13510,
      "Front Door Glass": 3084,
      "Rear Door Glass": 3084
    },
    "Sonata Embera": {
      "Laminated Windshield": 11960,
      "Front Door Glass": 2876,
      "Rear Door Glass": 2444,
      "Rear Quarter": 1123
    },
    "Creta Old": {
      "Laminated Windshield": 4888,
      "Front Door Glass": 1435,
      "Rear Door Glass": 962,
      "Rear Quarter": 426,
      "Backsceen": 2704,
      "Defogger Backscreen without wiper hole": 3848,
      "Defogger Backscreen with wiper hole": 3952,
      "Front Door Dark Green": 2392,
      "Rear Door Dark Green": 1602,
      "Rear Quarter Dark Green": 645
    },
    "Creta New 2020": {
      "Laminated Windshield": 4576,
      "Front Door Glass": 754,
      "Rear Door Glass": 541,
      "Backsceen": 2288,
      "Defogger Backscreen": 3172
    },
    "Alcazar": {
      "Laminated Windshield": 4576,
      "Front Door Glass": 988,
      "Rear Door Glass": 884,
      "Defogger Backscreen with wiper hole": 3068
    },
    "Venue": {
      "Laminated Windshield": 4472,
      "Front Door Glass": 946,
      "Rear Door Glass": 697,
      "Rear Quarter": 468,
      "Backsceen": 2704,
      "Defogger Backscreen without wiper hole": 3432,
      "Defogger Backscreen with wiper hole": 3224
    }
  },
  "Mahindra": {
    "540": {
      "Lamianted Windshield": 1872,
      "Front Door Glass": 728,
      "Rear Door Glass": 624,
      "Rear Quarter": 198,
      "Side Window": 624,
      "Backscreen Old (1010 X 444 mm)": 780,
      "Backscreen New (1978 X 410 mm)": 806
    },
    "Scorpio Hawk": {
      "Laminated Windshield with rain sensor": 5928
    },
    "Scorpio Old": {
      "Laminated Windshield": 5512,
      "Front Door Glass": 1040,
      "Rear Door Glass": 754,
      "Rear Quarter": 302,
      "Side Front": 541,
      "Side Rear": 541,
      "Side Window (Butterfly)": 1123,
      "Backsceen": 2496,
      "Defogger Backscreen": 3328
    },
    "Scorpio 2014": {
      "Laminated Windshield": 4888,
      "Laminated Windshield with rain sensor": 6188,
      "Front Door Glass": 1040,
      "Rear Door Glass": 754,
      "Rear Quarter": 302,
      "Side Window Front": 541,
      "Side Window Rear": 541,
      "Side Window (Butterfly)": 1123,
      "Backsceen": 2704,
      "Defogger Backscreen": 3640
    },
    "Scorpio 2022": {
      "Laminated Windshield": 6032,
      "Laminated Windshield with rain sensor": 6968,
      "Front Door Glass": 910,
      "Rear Door Glass": 858,
      "Side Window": 1976,
      "Side Window with SUS": 3380,
      "Backsceen": 2184,
      "Defogger Backscreen": 2548
    },
    "Scorpio N": {
      "Laminated Windshield": 6032,
      "Laminated Windshield with rain sensor": 6968,
      "Front Door Glass": 910,
      "Rear Door Glass": 858,
      "Side Window": 1976,
      "Side Window with SUS": 3380,
      "Backsceen": 2184,
      "Defogger Backscreen": 2548
    },
    "Xylo": {
      "Laminated Windshield": 5174,
      "Front Door Glass": 832,
      "Rear Door Glass": 806,
      "Side Window": 1248,
      "Defogger Backscreen": 4576
    },
    "Genio": {
      "Laminated Windshield": 5174,
      "Front Door Glass": 832
    },
    "XUV 500 Old": {
      "Laminated Windshield": 6240,
      "Laminated Windshield with Rain Sensor": 6968,
      "Front Door Glass": 1326,
      "Rear Door Glass": 1170,
      "Side Window": 1061,
      "Defogger Backscreen": 4368,
      "Front Door Dark Green": 1872,
      "Rear Door Dark Green": 1456,
      "Side Window Dark Green": 1575
    },
    "XUV 500 New": {
      "Laminated Windshield": 6240,
      "Laminated Windshield with Rain Sensor": 6968,
      "Front Door Glass": 1326,
      "Rear Door Glass": 1170,
      "Side Window": 1061,
      "Defogger Backscreen": 4368,
      "Front Door Dark Green": 1872,
      "Rear Door Dark Green": 1456,
      "Side Window Dark Green": 1575
    },
    "XUV 300": {
      "Laminated Windshield": 4420,
      "Laminated Windshield with Rain Sensor": 5148,
      "Front Door Glass": 754,
      "Rear Door Glass": 728,
      "Backscreen": 2496,
      "Defogger Backscreen": 2912
    },
    "XUV 400 EV": {
      "Laminated Windshield": 4420,
      "Laminated Windshield with Rain Sensor": 5148,
      "Front Door Glass": 754,
      "Rear Door Glass": 728,
      "Backscreen": 2496,
      "Defogger Backscreen": 2912
    },
    "XUV 700": {
      "Laminated Windshield": 6760,
      "Laminated Windshield with rain sensor": 7696,
      "Front Door Glass": 1123,
      "Rear Door Glass": 1123,
      "Side Window": 1976,
      "Side Window with SUS": 2210,
      "Backscreen without wiper hole": 3380,
      "Backscreen with wiper hole": 3328,
      "Defogger Backscreen with wiper hole": 4108
    },
    "Thar 2020": {
      "Laminated Windshield": 3588,
      "Front Door Glass": 702,
      "Rear Door Glass": 832,
      "Defogger Backscreen with wiper hole": 2912
    },
    "Marazzo": {
      "Laminated Windshield": 5720,
      "Front Ventch Glass": 1113,
      "Side Window": 2080,
      "Backscreen": 2433,
      "Defogger Backscreen": 3016
    },
    "Bolero Old": {
      "Laminated Windshield": 2756,
      "Front Door Glass": 728,
      "Rear Door Glass": 624,
      "Rear Quarter": 198,
      "Backscreen without wiper hole": 806,
      "Backscreen with wiper hole": 1300
    },
    "Armada": {
      "Lamianted Windshield": 1872,
      "Front Door Glass": 728,
      "Rear Door Glass": 624,
      "Rear Quarter": 198,
      "Side Window": 624,
      "Backscreen Old (1010 X 444 mm)": 780,
      "Backscreen New (1978 X 410 mm)": 806
    },
    "Marshal": {
      "Lamianted Windshield": 1872,
      "Front Door Glass": 728,
      "Rear Door Glass": 624,
      "Rear Quarter": 198,
      "Side Window": 624,
      "Backscreen Old (1010 X 444 mm)": 780,
      "Backscreen New (1978 X 410 mm)": 806
    },
    "Maxx Conqueror": {
      "Laminated Windshield": 2080
    },
    "Champion": {
      "Laminated Windshield": 2392
    },
    "Alpha": {
      "Laminated Windshield": 2808
    },
    "Logan": {
      "Laminated Windshield": 5200,
      "Front Door": 1279,
      "Rear Door": 1456
    },
    "Verito": {
      "Laminated Windshield": 5200,
      "Front Door": 1279,
      "Rear Door": 1456
    },
    "Vibe": {
      "Laminated Windshield": 5200,
      "Front Door": 1279,
      "Rear Door": 1456
    },
    "Major Jeep": {
      "Laminated Windshield": 2236
    },
    "Maxximo": {
      "Laminated Windshield": 3068,
      "Front Door Glass": 624
    },
    "Maxximo Mini Van": {
      "Side Window": 832,
      "Side Front Glass": 484,
      "Side Rear Glass": 489,
      "Backscreen": 1092
    },
    "Supro": {
      "Laminated Windshield": 3458
    },
    "Gio": {
      "Laminated Windshield": 2808,
      "Front Door Glass": 645
    },
    "Xylo Pickup": {
      "Rear Door": 806
    },
    "Quanto": {
      "Side Window": 1352,
      "Backscreen without wiper hole": 1924,
      "Defogger Backscreen": 2600,
      "Backscreen with wiper hole": 2444
    },
    "Nuvosport": {
      "Side Window": 1352,
      "Backscreen without wiper hole": 1924,
      "Defogger Backscreen": 2600,
      "Backscreen with wiper hole": 2444
    },
    "Rexton": {
      "Laminated Windshield": 10400,
      "Laminated Windshield with Rain Sensor": 14040
    },
    "TUV 300 Old": {
      "Lamianted Windshield": 4108,
      "Front Door Glass": 1602,
      "Rear Door Glass": 1404,
      "Side Window": 1758,
      "Backsceen with wiper hole": 2496,
      "Defogger Backscreen with wiper hole": 3432
    },
    "Bolero Neo": {
      "Lamianted Windshield": 4108,
      "Front Door Glass": 1602,
      "Rear Door Glass": 1404,
      "Rear Quarter": 1456,
      "Backsceen with wiper hole": 2653,
      "Defogger Backscreen with wiper hole": 3276
    },
    "TUV 300 New 2018": {
      "Lamianted Windshield": 4108,
      "Front Door Glass": 1602,
      "Rear Door Glass": 1404,
      "Rear Quarter": 1456,
      "Backsceen with wiper hole": 2653,
      "Defogger Backscreen with wiper hole": 3276
    },
    "KUV 100 Old": {
      "Lamianted Windshield": 3536,
      "Front Door Glass": 962,
      "Rear Door Glass": 858,
      "Backscreen": 1976,
      "Defogger Backscreen": 2652
    },
    "KUV 100 New 2017": {
      "Lamianted Windshield": 3536,
      "Front Door Glass": 962,
      "Rear Door Glass": 858,
      "Backscreen": 1768,
      "Defogger Backscreen": 2600
    },
    "Jeeto": {
      "Lamianted Windshield": 3432,
      "Front Door Glass": 728,
      "Front Vent": 276,
      "Backscreen": 494
    },
    "Reva Type 1": {
      "Laminated Windshield": 6032
    },
    "Reva Type 3": {
      "Laminated Windshield": 6032
    },
    "W Alfa-180 (EM1-E-Rickshaw)": {
      "Laminated Windshield": 1976
    },
    "Treo(E-Rickshaw)": {
      "Laminated Windshield": 2236
    }
  },
  "Tata": {
    "Indica": {
      "Laminated Windshield with SB": 3848,
      "Laminated Windshield": 3016,
      "Front Door Glass": 858,
      "Rear Door Glass": 463,
      "Rear Quarter": 276,
      "Backscreen without wiper hole": 1456,
      "Defogger Backscreen without wiper hole": 2600,
      "Defogger Backscreen with wiper hole": 2808
    },
    "Indigo": {
      "Laminated Windshield": 5616,
      "Front Door Glass": 806,
      "Backscreen": 2236
    },
    "Manza": {
      "Defogger Backscreen": 3328,
      "Backscreen": 1768
    },
    "Indica Vista": {
      "Laminated Windshield with SB": 4732,
      "Laminated Windshield": 3822,
      "Front Door Glass": 1310,
      "Rear Door Glass": 1352,
      "Rear Quarter": 416,
      "Backscreen without wiper hole": 1924,
      "Defogger Backscreen without wiper hole": 2600,
      "Defogger Backscreen with wiper hole": 3328
    },
    "Bolt": {
      "Laminated Windshield": 3822,
      "Front Door Glass": 1310,
      "Rear Door Glass": 1352,
      "Backscreen": 2496,
      "Defogger Backscreen": 2964
    },
    "Zest": {
      "Laminated Windshield": 3822,
      "Front Door Glass": 1310,
      "Rear Door Glass": 910,
      "Rear Quarter": 494,
      "Backscreen": 2860,
      "Defogger Backscreen": 3068
    },
    "Altorz": {
      "Laminated Windshield": 5512,
      "Laminated Windshield with Rain Sensor": 5720,
      "Front Door Glass": 962,
      "Rear Door Glass": 806,
      "Front Quarter": 1279,
      "Backscreen without wiper hole": 2366,
      "Defogger Backscreen with wiper hole": 3094
    },
    "Safari Old": {
      "Laminated Windshield": 6199,
      "Front Door Glass": 1466,
      "Rear Door Glass": 1071,
      "Rear Quarter": 539,
      "Side Window": 1310,
      "Backscreen": 1560,
      "Defogger Backscreen": 3380
    },
    "Safari Dicor": {
      "Laminated Windshield": 6708,
      "Front Door Glass": 1466,
      "Rear Door Glass": 1071,
      "Rear Quarter": 539,
      "Side Window": 1019,
      "Backscreen": 2080,
      "Defogger Backscreen": 2912
    },
    "Safari Storme": {
      "Laminated Windshield": 6812,
      "Front Door Glass": 1664,
      "Side Window": 1071,
      "Backscreen": 3432,
      "Defogger Backscreen with wiper hole": 3848,
      "Defogger Backscreen without wiper hole": 3848
    },
    "Safari New 2021": {
      "Laminated Windshield": 7696,
      "Front Door Glass": 988,
      "Rear Door Glass": 832
    },
    "Nexon": {
      "Laminated Windshield": 4680,
      "Laminated Windshield with Rain Sensor": 4784,
      "Front Door Glass": 962,
      "Rear Door Glass": 858
    },
    "Harrier": {
      "Laminated Windshield": 7696,
      "Front Door Glass": 988,
      "Rear Door Glass": 832
    },
    "Punch": {
      "Laminated Windshield": 4264,
      "Front Door Glass": 832,
      "Rear Door Glass": 728
    },
    "Tiago": {
      "Laminated Windshield": 4888,
      "Laminated Windshield with Rain Sensor": 5928,
      "Front Door Glass": 1560,
      "Rear Door Glass": 936,
      "Backscreen": 2288,
      "Defogger Backscreen": 2756
    },
    "Tigor": {
      "Laminated Windshield": 4888,
      "Laminated Windshield with Rain Sensor": 5928,
      "Front Door Glass": 1092,
      "Rear Door Glass": 858,
      "Rear Quarter": 240,
      "Backscreen": 2028,
      "Defogger Backscreen": 2756
    },
    "Intra": {
      "Laminated Windshield": 3692,
      "Front Door Glass": 884
    },
    "Ace": {
      "Laminated Windshield": 2600,
      "Front Door Glass": 702
    },
    "Ace EV": {
      "Laminated Windshield": 2600,
      "Front Door Glass": 702
    },
    "Facelift": {
      "Laminated Windshield": 2600,
      "Front Door Glass": 702
    },
    "Ace Super": {
      "Laminated Windshield": 3120,
      "Front Door Glass": 858,
      "Fix Quarter": 598,
      "Side Window": 1898,
      "Rear Quarter": 910,
      "Backscreen": 1924,
      "Backscreen with wiper hole": 1924,
      "Defogger Backscreen": 2600
    },
    "Venture": {
      "Laminated Windshield": 3120,
      "Front Door Glass": 858,
      "Fix Quarter": 598,
      "Side Window": 1898,
      "Rear Quarter": 910,
      "Backscreen": 1924,
      "Backscreen with wiper hole": 1924,
      "Defogger Backscreen": 2600
    },
    "Microbus": {
      "Laminated Windshield": 3120,
      "Front Door Glass": 858,
      "Fix Quarter": 598,
      "Side Window": 1898,
      "Rear Quarter": 910,
      "Backscreen": 1924,
      "Backscreen with wiper hole": 1924,
      "Defogger Backscreen": 2600
    },
    "Ace Penguin": {
      "Laminated Windshield": 2860,
      "Front Door Glass": 494,
      "Backscreen": 520
    },
    "Magic Iris": {
      "Laminated Windshield": 2860,
      "Front Door Glass": 494,
      "Backscreen": 520
    },
    "Sumo": {
      "Laminated Windshield": 3692,
      "Front Door Glass": 858,
      "Rear Door Glass": 650,
      "Side Front": 546,
      "Side Rear": 494,
      "Rear Quarter": 286,
      "Backscreen": 1196,
      "Defogger Backscreen": 1144
    },
    "Mobile": {
      "Laminated Windshield": 3328,
      "Front Door Glass": 494,
      "Rear Door Glass": 494,
      "Backscreen": 1144,
      "Defogger Backscreen": 2054
    },
    "Nano": {
      "Laminated Windshield": 3744,
      "Front Door Glass": 962,
      "Rear Door Glass": 832
    },
    "Winger": {
      "Laminated Windshield": 4940
    },
    "Xenon": {
      "Laminated Windshield": 5096
    },
    "Sumo Grade": {
      "Laminated Windshield": 4784
    }
  },
  "Skoda": {
    "Fabia": {
      "Laminated Windshield": 6760
    },
    "Octavia Old": {
      "Laminated Windshield": 9152
    },
    "Octavia 2014": {
      "Laminated Windshield with rain sensor": 15080,
      "Front Door": 1654,
      "Rear Door": 1336,
      "Defogger Backscreen": 6344
    },
    "Laura": {
      "Laminated Windshield with SB": 9672,
      "Front Door": 1706,
      "Rear Door": 1498
    },
    "Yeti": {
      "Front Door Glass": 1602,
      "Rear Door Glass": 1394,
      "Side Window Glass": 1394
    },
    "Superb Old": {
      "Front Door Glass": 1820,
      "Rear Door Glass": 1602
    },
    "Superb 2019": {
      "Front Door Glass": 3822,
      "Rear Door Glass": 2782
    },
    "Kodiaq": {
      "Front Door Glass": 2886,
      "Rear Door Glass": 2990
    },
    "Kushaq": {
      "Laminated Windshield": 5408,
      "Laminated Windshield with rain sensor": 5408,
      "Backscreen": 1560,
      "Defogger Backscreen": 2392
    },
    "Slavia": {
      "Laminated Windshield": 4992,
      "Laminated Windshield with rain sensor": 5044,
      "Rear Quarter": 1251,
      "Side Window without SUS": 1144,
      "Side Window with L-Type SUS": 1872,
      "Side Window with Straight SUS": 1560,
      "Defogger Backscreen": 3536
    }
  },
  "Honda": {
    "City Old Type I": {
      "Laminated Windshield": 4368,
      "Front Door Glass": 1300,
      "Rear Door Glass": 936,
      "Rear Quarter": 416,
      "Defogger Backscreen": 4056
    },
    "City Asia Type II": {
      "Laminated Windshield": 4836,
      "Front Door Glass": 1352,
      "Rear Door Glass": 1040,
      "Front Quarter": 416,
      "Defogger Backscreen": 4680
    },
    "City Old 2008 Type III": {
      "Laminated Windshield": 6448,
      "Front Door Glass": 1248,
      "Rear Door Glass": 1040,
      "Rear Quarter": 312,
      "Defogger Backscreen": 6032
    },
    "City 2014 Type IV": {
      "Laminated Windshield": 6240,
      "Front Door Glass": 1456,
      "Rear Door Glass": 1248,
      "Rear Quarter": 286,
      "Backscreen": 3848,
      "Defogger Backscreen": 5096
    },
    "City 2017 Type V": {
      "Laminated Windshield": 6656,
      "Laminated Windshield with rain sensor": 8112,
      "Laminated Windshield Acoustic": 8008,
      "Laminated Windshield Acoustic with rain sensor": 8424,
      "Front Door Glass": 1456,
      "Rear Door Glass": 1248,
      "Rear Quarter": 286,
      "Backscreen": 3848,
      "Defogger Backscreen": 5096
    },
    "City 2020 Type VI": {
      "Laminated Windshield": 7124,
      "Laminated Windshield with rain sensor": 7540,
      "Front Door Glass": 1456,
      "Rear Door Glass": 1248,
      "Backscreen with low defogger ": 4992,
      "Defogger Backscreen without wiper hole": 4784
    },
    "City New 2022 Type VII": {
      "Laminated Windshield": 8424,
      "Laminated Windshield with rain sensor": 8424,
      "Laminated Windshield with camera bracket": 9048,
      "Laminated Windshield with rain sensor and camera bracket": 13000
    },
    "Accord Old Type I": {
      "Laminated Windshield": 20540,
      "Front Door Glass": 1326,
      "Rear Door Glass": 1175,
      "Rear Quarter": 520
    },
    "Accord 2003 Type II": {
      "Laminated Windshield": 15600,
      "Front Door Glass": 1248,
      "Rear Door Glass": 936,
      "Rear Quarter": 416
    },
    "Accord 2PX 2008 Type III": {
      "Laminated Windshield": 20566,
      "Front Door Glass": 1872,
      "Rear Door Glass": 1456,
      "Rear Quarter": 520
    },
    "Civic Old": {
      "Laminated Windshield": 10400,
      "Front Door Glass": 1560,
      "Rear Door Glass": 1144,
      "Front Vent": 520,
      "Rear Quarter": 416,
      "Defogger Backscreen": 7800
    },
    "Civic New 2019": {
      "Laminated Windshield": 14400,
      "Front Door Glass": 3016,
      "Rear Door Glass": 2964,
      "Defogger Backscreen": 5720
    },
    "Crv Old": {
      "Laminated Windshield": 18720
    },
    "Jazz Old 2010": {
      "Laminated Windshield": 5200,
      "Defogger Backscreen": 3900
    },
    "Jazz 2015": {
      "Laminated Windshield": 4992,
      "Front Door Glass": 1248,
      "Rear Door Glass": 1040,
      "Front Vent": 728,
      "Rear Quarter": 624,
      "Defogger Backscreen": 3900
    },
    "WRV 2017": {
      "Laminated Windshield Acoustic": 5096,
      "Front Door Glass": 1248,
      "Rear Door Glass": 1040,
      "Front Vent": 728,
      "Rear Quarter": 624,
      "Defogger Backscreen": 3900
    },
    "WRV 2020": {
      "Laminated Windshield": 7176,
      "Front Door Glass": 1248,
      "Rear Door Glass": 1040,
      "Front Vent": 728,
      "Rear Quarter": 624,
      "Defogger Backscreen": 3900
    },
    "Brio": {
      "Laminated Windshield": 4680,
      "Front Door Glass": 1196,
      "Rear Door Glass": 858,
      "Backscreen": 3432,
      "Defogger Backscreen": 3952
    },
    "Amaze Old": {
      "Laminated Windshield": 4680,
      "Front Door Glass": 1196,
      "Rear Door Glass": 1092,
      "Rear Quarter": 936,
      "Backscreen": 3328,
      "Defogger Backscreen": 4368
    },
    "Amaze New 2018": {
      "Laminated Windshield": 4992,
      "Front Door Glass": 1222,
      "Rear Door Glass": 1092,
      "Defogger Backscreen": 3432
    },
    "Mobilio": {
      "Laminated Windshield": 4680,
      "Front Door Glass": 1430,
      "Rear Door Glass": 1248,
      "Rear Quarter": 1040,
      "Defogger Backscreen": 4992
    },
    "BRV": {
      "Laminated Windshield": 4680,
      "Front Door Glass": 1430,
      "Rear Door Glass": 1248,
      "Rear Quarter": 1040,
      "Backscreen": 2626,
      "Defogger Backscreen": 3432
    }
  },
  "General Motors (GM)": {
    "Beat": {
      "Laminated Windshield": 3744,
      "Laminated Windshield with SB": 4212,
      "Backscreen": 1716,
      "Defogger Backscreen": 2600
    },
    "Spark": {
      "Laminated Windshield": 4004,
      "Front Door Glass": 1071,
      "Rear Door Glass": 962
    },
    "Tavera": {
      "Laminated Windshield": 4992,
      "Lamiated Windshield with SB": 6916,
      "Front Door Glass": 1602,
      "Rear Door Glass": 962,
      "Rear Quarter": 494,
      "Side Window with hole": 1394,
      "Side Window without hole": 910,
      "Backscreen": 2288,
      "Backscreen with Carrier Plate": 2392,
      "Defogger Backscreen": 3016
    },
    "Aveo Old": {
      "Laminated Windshield with SB": 6032,
      "Front Door": 1602,
      "Rear Door": 1492,
      "Backscreen": 2600,
      "Defogger Backscreen": 3432
    },
    "Captiva": {
      "Laminated Windshield": 10400
    },
    "Cruze": {
      "Laminated Windshield with rain sensor": 8216
    },
    "Aveo 2014": {
      "Laminated Windshield": 5200
    },
    "Sail 2014": {
      "Laminated Windshield": 5200
    },
    "Enjoy": {
      "Laminated Windshield with SB": 5512,
      "Backscreen": 2392
    },
    "Opel Astra": {
      "Laminated Windshield with SB": 5200,
      "Front Door": 1175,
      "Rear Door": 962,
      "Quarter without encapsulation": 598,
      "Defogger Backscreen": 3328
    },
    "Opel Corsa": {
      "Laminated Windshield with SB": 5096,
      "Front Door": 1175,
      "Rear Door": 962,
      "Encapsulated Quarter": 3302,
      "Backscreen": 2808,
      "Defogger Backscreen": 3432
    },
    "Sail": {
      "Laminated Windshield with SB": 5096,
      "Front Door": 1175,
      "Rear Door": 962,
      "Encapsulated Quarter": 3302,
      "Backscreen": 2808,
      "Defogger Backscreen": 3640
    },
    "Swing": {
      "Laminated Windshield with SB": 5096,
      "Front Door": 1175,
      "Rear Door": 962,
      "Encapsulated Quarter": 3302,
      "Backscreen": 2808,
      "Defogger Backscreen": 3640
    },
    "Optra": {
      "Laminated Windshield with SB": 6136,
      "Front Door": 1388,
      "Rear Door": 1071
    },
    "Uva": {
      "Laminated Windshield": 5616,
      "Laminated Windshield with SB": 5408,
      "Front Door": 1602,
      "Rear Door": 1492,
      "Side Window": 858,
      "Backscreen": 2340,
      "Defogger Backscreen": 3016
    }
  },
  "Toyota": {
    "Corolla Old": {
      "Laminated Windshield": 6136,
      "Laminated Windshield with SB": 6604
    },
    "Corolla Altis Old": {
      "Laminated Windshield": 9880,
      "Laminated Windshield with SB": 11648,
      "Front Door": 2028,
      "Rear Door": 1352,
      "Rear Quarter": 1040,
      "Defogger Backscreen": 5616
    },
    "Corolla Altis 2014": {
      "Laminated Windshield": 12012,
      "Laminated Windshield with SB": 11440,
      "Laminated Windshield with SB & rain sensor": 12012,
      "Front Door": 2392,
      "Rear Door": 2288,
      "Rear Quarter": 936,
      "Defogger Backscreen": 5824
    },
    "Innova": {
      "Laminated Windshield": 5720,
      "Front Door": 1768,
      "Rear Door": 1560,
      "Front Vent": 1820,
      "Rear Quarter": 702,
      "Side Window": 2496,
      "Backscreen": 3276,
      "Defogger Backscreen": 4784
    },
    "Innova Crysta": {
      "Laminated Windshield": 7904,
      "Front Door": 2080,
      "Rear Door": 1768,
      "Front Vent": 1872,
      "Front Quarter with Chrome Molding": 2054,
      "Side Window": 2912,
      "Side Window with Chrome Molding": 3328,
      "Rear Quarter": 728,
      "Backscreen with wiper hole with molding": 5720,
      "Backscreen with wiper hole": 5307,
      "Defogger Backscreen": 5720,
      "Dark Green Front Door Glass": 2028,
      "Dark Green Rear Door Glass": 1768,
      "Dark Green Rear Quarter Glass": 946,
      "Dark Green Side Window Glass": 2699,
      "Dark Green Front Quarter Glass": 1750
    },
    "Innova Hycross": {
      "Laminated Windshield": 8112,
      "Laminated Windshield Acoustic": 8424,
      "Laminated Windshield IR Cut & Acoustic": 8944,
      "Laminated Windshield IR Cut + Acoustic + TSS Bracket": 10296,
      "Front Door": 2028,
      "Front Quarter Encapsulated": 1144,
      "Rear Door": 1820,
      "Side Window": 2444,
      "Side Window with Chrome Molding without GBS": 2756,
      "Side Window with Chrome Molding with GBS": 3380,
      "Backscreen": 5720,
      "Defogger Backscreen": 7280
    },
    "Etios": {
      "Laminated Windshield": 4472,
      "Front Door": 1248,
      "Rear Door": 1124,
      "Backscreen": 2444,
      "Defogger Backscreen": 3640
    },
    "Etios Liva": {
      "Laminated Windshield": 4472,
      "Front Door": 1248,
      "Rear Door": 1014,
      "Backscreen": 2912,
      "Defogger Backscreen": 3744
    },
    "Glanza": {
      "Laminated Windshield": 4420,
      "Laminated Windshield with rain sensor": 4500,
      "Front Door": 1310,
      "Rear Door": 988,
      "Rear Quarter with SUS": 1560,
      "Rear Quarter without SUS": 1144,
      "Backscreen": 2756,
      "Defogger Backscreen": 3536,
      "Dark Green Front Door Glass": 2236,
      "Dark Green Rear Door Glass": 1664
    },
    "Qualis": {
      "Laminated Windshield with SB": 4108
    },
    "Urban Cruser": {
      "Laminated Windshield": 5044,
      "Laminated Windshield with rain sensor": 5200,
      "Front Door": 1014,
      "Rear Door": 780,
      "Rear Quarter": 1352,
      "Backscreen": 1872,
      "Defogger Backscreen without wiper hole": 2652,
      "Defogger Backscreen with wiper hole": 2600
    },
    "Fortunner Old": {
      "Laminated Windshield with SB": 12480,
      "Front Door": 3094,
      "Rear Door": 2860,
      "Rear Quarter": 1612,
      "Side Window Left": 6136,
      "Side Window Right": 6760,
      "Defogger Backscreen": 8008
    },
    "Fortunner 2016": {
      "Laminated Windshield with SB": 12740,
      "Front Door": 2496,
      "Rear Door": 2028,
      "Rear Quarter": 520,
      "Side Window": 3224,
      "Defogger Backscreen": 7488
    },
    "Fortunner New 2019": {
      "Laminated Windshield with SB": 13260,
      "Front Door": 2496,
      "Rear Door": 2028,
      "Rear Quarter": 520,
      "Side Window": 3224,
      "Defogger Backscreen": 7488
    },
    "Hyryder": {
      "Laminated Windshield": 6656,
      "Front Door": 1040,
      "Frot Door Dark Green": 1872,
      "Rear Door": 936,
      "Rear Door Dark Green": 1560,
      "Rear Quarter without SUS": 1144,
      "Rear Quarter with SUS": 1222,
      "Rear Quarter Dark Green with SUS": 1378,
      "Defogger Backscreen without wiper hole": 3328,
      "Defogger Backscreen with wiper hole": 3411
    },
    "Hilux": {
      "Laminated Windshield Solar": 13260,
      "Front Door Solar Green": 3172,
      "Rear Door Solar Green": 2496,
      "Rear Quarter Solar Green": 478,
      "Backscreen Solar Green": 8320
    },
    "Yaris": {
      "Laminated Windshield": 6500,
      "Laminated Windshield IR Cut": 6812,
      "Laminated Windshield IR Cut + Acoustic": 6708,
      "Front Door": 1664,
      "Rear Door": 1300,
      "Rear Quarter": 520,
      "Defogger Backscreen": 4680
    },
    "Camry Old": {
      "Front Door": 3120,
      "Rear Door": 2600
    },
    "Lexus": {
      "Front Door": 5200,
      "Rear Door": 3640
    }
  },
  "Kia Motor": {
    "Carnes": {
      "Laminated Windshield": 4888,
      "Front Door": 1092,
      "Rear Door": 936,
      "Rear Quarter without SUS": 1534,
      "Rear Quarter with SUS": 1654,
      "Backscreen without wiper hole": 1508,
      "Backscreen with wiper hole": 1820,
      "Defogger Backscreen with wiper hole": 1976
    },
    "Sonet": {
      "Laminated Windshield": 4160,
      "Front Door": 1040,
      "Rear Door": 832,
      "Rear Quarter with SUS": 1144,
      "Backscreen": 2444,
      "Defogger Backscreen without wiper hole": 2808,
      "Defogger Backscreen with wiper hole": 2834
    },
    "Seltos": {
      "Laminated Windshield": 4992,
      "Laminated Windshield with rain sensor": 5512,
      "Front Door": 1144,
      "Rear Door": 936,
      "Rear Quarter Green without SUS": 1815,
      "Rear Quarter Green with SUS": 2079,
      "Rear Quarter Dark Green without SUS": 2184,
      "Rear Quarter Dark Green with SUS": 2340
    },
    "Hector": {
      "Laminated Windshield without sensor": 7800,
      "Front Door": 868,
      "Rear Door": 858,
      "Side Quarter": 3016,
      "Defogger Backscreen": 3952
    },
    "Astor": {
      "Front Door Glass": 1435,
      "Rear Door Glass": 1435,
      "Side Quarter with SUS": 1279
    },
    "Gloster": {
      "Laminated Windshield": 11180,
      "Laminated Windshield with rain sensor": 11336,
      "Front Door": 2132,
      "Rear Door": 1602,
      "Side Window": 10618,
      "Side Window with SUS": 13270,
      "Defogger Backscreen": 8372
    }
  },
  "Ford": {
    "Figo": {
      "Laminated Windshield": 4420,
      "Front Door": 912,
      "Rear Door": 806,
      "Defogger Backscreen": 2496
    },
    "Figo Aspire": {
      "Laminated Windshield": 4550,
      "Front Door": 912,
      "Rear Door": 806,
      "Defogger Backscreen": 2496
    },
    "Figo Freestyle": {
      "Laminated Windshield": 5512
    },
    "Ikon": {
      "Laminated Windshield": 5356,
      "Backscreen": 2573,
      "Defogger Backscreen": 3354
    },
    "Endevour Old": {
      "Laminated Windshield": 9360,
      "Front Door": 1456,
      "Rear Door": 1560,
      "Rear Quarter": 962,
      "Side Window": 2928
    },
    "Endevour 2017": {
      "Laminated Windshield": 15080,
      "Defogger Backscreen": 6058
    },
    "Fiesta Old": {
      "Laminated Windshield": 5460,
      "Front Door": 1602,
      "Front Door with holes": 1820,
      "Rear Door": 1071,
      "Rear Quarter": 650,
      "Backscreen": 2626,
      "Defogger Backscreen": 3822
    },
    "Fiesta New": {
      "Laminated Windshield": 5824,
      "Front Door": 1014,
      "Rear Door": 858
    },
    "Ecosport Old": {
      "Laminated Windshield": 4810,
      "Laminated Windshield with rain sensor": 4888,
      "Front Door": 728,
      "Rear Door": 936,
      "Rear Quarter": 884,
      "Backscreen": 3016,
      "Defogger Backscreen": 3718
    },
    "Ecosport New 2018": {
      "Laminated Windshield": 4888,
      "Laminated Windshield with rain sensor": 4992,
      "Laminated Windshield acoustic + rain sensor": 5928,
      "Front Quarter": 874,
      "Rear Quarter with SUS": 910
    },
    "Fusion": {
      "Laminated Windshield": 5356
    }
  },
  "Volkswagon": {
    "Polo": {
      "Laminated Windshield": 4836,
      "Laminated Windshield with rain sensor": 5512,
      "Front Door": 1061,
      "Rear Door": 1045,
      "Rear Quarter": 1227,
      "Backscreen": 2288,
      "Defogger Backscreen": 3328
    },
    "Vento": {
      "Laminated Windshield": 5668,
      "Laminated Windshield with rain sensor": 5876,
      "Front Door": 1820,
      "Rear Door": 1487,
      "Rear Quarter": 1633,
      "Backscreen": 2444,
      "Defogger Backscreen": 3224
    },
    "Virtus": {
      "Laminated Windshield": 4992,
      "Laminated Windshield with rain sensor": 5044,
      "Rear Quarter": 1251,
      "Rear Quarter with encapsulation": 1144,
      "Defogger Backscreen": 3536
    }
  },
  "Renault": {
    "Duster": {
      "Laminated Windshield": 5148,
      "Front Door Glass": 1560,
      "Rear Door Glass": 1664,
      "Defogger Backscreen": 3432
    }
  }
};

function CarGlassPriceCalculator() {
  const [make, setMake] = useState('');
  const [model, setModel] = useState('');
  const [glassType, setGlassType] = useState('');
  const [price, setPrice] = useState(null);


  useEffect(() => {

    if (!glassType) {
      setPrice(null); // Reset price when glassType is not selected
      return;
    }

    // Default additional price
    let additionalPrice = 0;
    const glassTypeLower = glassType.toLowerCase();
    // Adjust additional price based on glassType


    if (glassTypeLower.includes("windshield")) {
      additionalPrice = 1500;
    } else if (glassTypeLower.includes("door")) {
      additionalPrice = 1000;
    } else if (glassTypeLower.includes("defogger") || glassTypeLower.includes("backscreen")) {
      additionalPrice = 1300;
    } else if (glassTypeLower.includes("green") || glassTypeLower.includes("window") || glassTypeLower.includes("vent") || glassTypeLower.includes("quarter")) {
      additionalPrice = 1000
    }

    // Update price state
    setPrice((currentPrice) => currentPrice + additionalPrice);
  }, [glassType]); // This effect depends on glassType

  const handleMakeChange = (e) => {
    setMake(e.target.value);
    setModel('');
    setGlassType('');
    setPrice('');
  };

  const handleModelChange = (e) => {
    setModel(e.target.value);
    setGlassType('');
    setPrice('');
  };

  const handleGlassTypeChange = (e) => {
    setGlassType(e.target.value);
    const selectedPrice = car_data[make][model][e.target.value];
    setPrice(selectedPrice);
  };

  return (
    <div className="container" id="car-details-container">
  <h2 className="title" id="car-details-title">Select Your Car Details</h2>
  <div className="dropdown" id="car-make-dropdown-container">
    <label className="label" id="car-make-label">
      Car Make:
      <select className="select" id="car-make-select" value={make} onChange={handleMakeChange}>
        <option value="">Select Make</option>
        {Object.keys(car_data).map((make) => (
          <option key={make} value={make}>{make}</option>
        ))}
      </select>
    </label>
  </div>
  <div className="dropdown" id="car-model-dropdown-container">
    <label className="label" id="car-model-label">
      Car Model:
      <select className="select" id="car-model-select" value={model} onChange={handleModelChange} disabled={!make}>
        <option value="">Select Model</option>
        {make && Object.keys(car_data[make]).map((model) => (
          <option key={model} value={model}>{model}</option>
        ))}
      </select>
    </label>
  </div>
  <div className="dropdown" id="glass-type-dropdown-container">
    <label className="label" id="glass-type-label">
      Glass Type:
      <select className="select" id="glass-type-select" value={glassType} onChange={handleGlassTypeChange} disabled={!model}>
        <option value="">Select Glass Type</option>
        {model && Object.keys(car_data[make][model]).map((glassType) => (
          <option key={glassType} value={glassType}>{glassType}</option>
        ))}
      </select>
    </label>
  </div>

          
  {/* Conditionally render price information 
  {price !== null && (
    price && <div className="price-display" id="price-display"><strong>Price: ₹{price}</strong></div>
  )}       */} 
  {price && <div className="price-display" id="price-display"><strong>Price: ₹{price}</strong></div>}


  <div className="price-note" id="price-note">
  {price && <div className="price-note" id="price-note">Note : The price is inclusive of installation, GST and labour charges.</div>}
  </div>


  </div>
  );
}

export default CarGlassPriceCalculator;
